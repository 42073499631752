import React, { useState } from 'react';
import './CopyButton.css'; // Import CSS file

const CopyButton = ({ content }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(content);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return (
        <div className="copy-button w-100">
            <h6>{content}</h6>
            <div
                className="copy-icon"
                role="button"
                onClick={handleCopy}
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                        handleCopy();
                    }
                }}
                tabIndex={0}
            >
                <i className="fas fa-copy"></i>
                {copied && (
                    <div className="copy-message" role="tooltip">
                        Copied
                        <div className="arrow-down"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CopyButton;
