import React, { useState } from "react";
import "./TransferList.css";

const TransferList = ({
  transferItems,
  selectedItems,
  itemSelector,
  ListRow,
  isSelectedListDisabled = false,
}) => {
  const [selectedAvailableItems, setSelectedAvailableItems] = useState([]);
  const [selectedSelectedItems, setSelectedSelectedItems] = useState([]);
  const [availableSearch, setAvailableSearch] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");

  const handleAvailableItemChange = (id) => {
    const updatedSelection = selectedAvailableItems.includes(id)
      ? selectedAvailableItems.filter((itemId) => itemId !== id)
      : [...selectedAvailableItems, id];
    setSelectedAvailableItems(updatedSelection);
  };

  const handleSelectedItemChange = (id) => {
    const updatedSelection = selectedSelectedItems.includes(id)
      ? selectedSelectedItems.filter((itemId) => itemId !== id)
      : [...selectedSelectedItems, id];
    setSelectedSelectedItems(updatedSelection);
  };

  const moveItemsToSelected = () => {
    const updatedSelectedItems = [
      ...selectedItems,
      ...selectedAvailableItems,
    ].filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates
    itemSelector(updatedSelectedItems);
    setSelectedAvailableItems([]);
  };

  const moveItemsToAvailable = () => {
    const updatedSelectedItems = selectedItems.filter(
      (itemId) =>
        !selectedSelectedItems.includes(itemId) ||
        transferItems.find((item) => item.ehr_scope_id === itemId)?.isFixed
    );
    itemSelector(updatedSelectedItems);
    setSelectedSelectedItems([]);
  };

  const filteredTransferItems = transferItems?.filter(
    (item) =>
      !selectedItems.includes(item.ehr_scope_id) &&
      item.ehr_scope_name.toLowerCase().includes(availableSearch.toLowerCase())
  );

  const filteredSelectedItems = selectedItems.filter((itemId) =>
    transferItems
      .find((item) => item.ehr_scope_id === itemId)
      ?.ehr_scope_name.toLowerCase()
      .includes(selectedSearch.toLowerCase())
  );

  return (
    <div className="transfer-list">
      <div className="list-container">
        {!transferItems || transferItems.length === 0 ? (
          <div className="empty-message">Please select an EHR provider</div>
        ) : (
          <>
            <div className="available-items">
              <h3>Available Items</h3>
              <input
                type="text"
                className="search-bar"
                placeholder="Search Available Items"
                value={availableSearch}
                onChange={(e) => setAvailableSearch(e.target.value)}
              />
              <div className="available-list-scroll">
                {filteredTransferItems?.length > 0 ? (
                  filteredTransferItems?.map((item) => (
                    <div key={item.ehr_scope_id} className="list-item">
                      <input
                        type="checkbox"
                        onChange={() =>
                          handleAvailableItemChange(item.ehr_scope_id)
                        }
                        checked={selectedAvailableItems.includes(
                          item.ehr_scope_id
                        )}
                      />
                      <span
                        className="item-name"
                        onClick={() =>
                          handleAvailableItemChange(item.ehr_scope_id)
                        }
                      >
                        <ListRow {...item} />
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="empty-message">No available items</div>
                )}
              </div>
            </div>
            <div className="transfer-buttons">
              <button
                onClick={moveItemsToSelected}
                disabled={!selectedAvailableItems.length}
              >
                &gt;&gt;
              </button>
              <button
                onClick={moveItemsToAvailable}
                disabled={!selectedSelectedItems.length}
              >
                &lt;&lt;
              </button>
            </div>
            <div className="selected-items">
              <h3>Selected Items</h3>
              <input
                type="text"
                className="search-bar"
                placeholder="Search Selected Items"
                value={selectedSearch}
                onChange={(e) => setSelectedSearch(e.target.value)}
              />
              <div className="available-list-scroll">
                {filteredSelectedItems?.length > 0 ? (
                  filteredSelectedItems?.map((itemId) => {
                    const item = transferItems.find(
                      (item) => item.ehr_scope_id === itemId
                    );
                    return (
                      <div key={itemId} className="list-item">
                        <input
                          type="checkbox"
                          onChange={() => handleSelectedItemChange(itemId)}
                          checked={selectedSelectedItems?.includes(itemId)}
                          disabled={isSelectedListDisabled}
                        />
                        <span
                          className="item-name"
                          onClick={() =>
                            !isSelectedListDisabled &&
                            handleSelectedItemChange(itemId)
                          }
                        >
                          <ListRow {...item} />
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <div className="empty-message">No selected items</div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TransferList;
