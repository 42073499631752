import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  page_size: 25,
  search: "",
  customerList: [],
  total_pages: 0,
  totalRecords: 0,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerList: (state, action) => {
      state.customerList = action.payload.org_ehr_configs;
      state.totalRecords = action.payload.total_records;
      state.total_pages = Math.ceil(
        action.payload.total_records / state.page_size
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.page_size = action.payload;
      state.page = 1; // Reset to the first page
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { setCustomerList, setPage, setPageSize, setSearch } =
  customerSlice.actions;

export default customerSlice.reducer;
