import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";

import moment from "moment";

const DashHeader = () => {
  return (
    <>
      <div className="add-position">
        <header className="main-header login-header-home remove-position">
          <nav className="navbar navbar-expand-lg  sticky-top">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/user/patient-list/all">
                <img
                  src="/assets/images/newLogo.png"
                  className="img-fluid"
                  alt="logo"
                />
              </Link>

              <div className="d-flex align-items-center gap-2">
                <div className="dropdown nav-item dropdown-header p-0">
                  <button
                    className=" nav-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/images/avatar.png"
                      alt="..."
                      className="img-fluid rounded-circle "
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink
                        exact={true}
                        activeClassName="active"
                        className="dropdown-item"
                        to="/user/profile"
                      >
                        Organization
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact={true}
                        activeClassName="active"
                        className="dropdown-item"
                        to="/user/change-password"
                      >
                        Change Password
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact={true}
                        to={"/login"}
                        activeClassName="active"
                        className="dropdown-item"
                        onClick={() => {
                          localStorage.clear();
                        }}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default React.memo(DashHeader);
