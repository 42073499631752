import React, { useEffect, useState } from "react";
import {
  usePostForgotPasswordMutation,
  usePostUserLoginMutation,
} from "../../services/apiService";
import Slider from "react-slick";
import { loginSchema } from "../../utils/validationSchema/validation";
import { Formik } from "formik";
import InputField from "../../components/elements/inputs/InputField";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/elements/Spinner/Spinner";
import { toast } from "react-toastify";
import { decodeToken } from "react-jwt";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import { REVERSE_ROLES } from "../../utils/commonFunction";
import { useDispatch } from "react-redux";
import { verifyOtp } from "../../redux/slice/authSlice";

const Login = () => {
  const dispatch = useDispatch();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [passwordType, setPasswordType] = useState("password");
  const [icon, setIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [ipAddress, setIPAddress] = useState("");

  const [deviceType, setDeviceType] = useState("Unknown");
  const [browserInfo, setBrowserInfo] = useState("Unknown");
  const navigate = useNavigate();

  const [
    postUserLogin,
    { isLoading: isLoginLoading, isFetching: isLoginFetching },
  ] = usePostUserLoginMutation();
  const [postForgotPassword, { isLoading, isFetching }] =
    usePostForgotPasswordMutation();

  const _changeIcon = () =>
    icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
      ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"),
        setPasswordType("text"))
      : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"),
        setPasswordType("password"));

  useEffect(() => {
    // Fetch IP address from a different API
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        console.log("IP Address Data:", data); // Log the data
        setIPAddress(data?.ip || "N/A");
      })
      .catch((error) => console.error("Failed to fetch IP address:", error));

    // Detect device type based on user agent
    const userAgent = navigator.userAgent;
    const mobileDeviceRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const tabletDeviceRegex = /iPad/i;

    if (mobileDeviceRegex.test(userAgent)) {
      setDeviceType("Mobile");
    } else if (tabletDeviceRegex.test(userAgent)) {
      setDeviceType("Tablet");
    } else {
      setDeviceType("Desktop");
    }

    // Detect browser name and version
    const browserName = navigator.appName;
    const browserVersion = navigator.appVersion;
    setBrowserInfo(`${browserName} ${browserVersion}`);
  }, []);

  const onLoginSubmit = async (values, resetForm) => {
    const payload = {
      email: values?.email,
      password: values?.password,
      device_browser: browserInfo || "",
      device_type: deviceType || "",
      device_ip: ipAddress || "",
      device_token: "",
    };

    postUserLogin({ query: "", payload: payload })
      .then((res) => {
        if (res?.data?.isUserVerified) {
          const userData = decodeToken(res?.data?.token);

          const encryptedToken = CryptoJS.AES.encrypt(
            res?.data?.token,
            REACT_APP_CRYPTOJS_SECRETKEY,
            { iv: REACT_APP_CRYPTOJS_VECTOR }
          ).toString();

          const encrypteOrg = CryptoJS.AES.encrypt(
            JSON.stringify(res?.data?.user_data),
            REACT_APP_CRYPTOJS_SECRETKEY,
            { iv: REACT_APP_CRYPTOJS_VECTOR }
          ).toString();

          localStorage.setItem("accessToken", encryptedToken);
          localStorage.setItem("isUserVerified", res?.data?.isUserVerified);
          localStorage.setItem("org_data", encrypteOrg);

          const role = REVERSE_ROLES[userData?.user?.role];

          toast.success("Authentication successful");
          dispatch(verifyOtp());
          navigate(`/${role?.toLowerCase()}/home`);
        } else if (res?.data?.isUserVerified === false && res?.data?.token) {
          const encryptedToken = CryptoJS.AES.encrypt(
            res?.data?.token,
            REACT_APP_CRYPTOJS_SECRETKEY,
            { iv: REACT_APP_CRYPTOJS_VECTOR }
          ).toString();

          localStorage.setItem("accessToken", encryptedToken);
          navigate("/verify-code", {
            state: {
              email: values?.email,
            },
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        localStorage.clear();
      });
  };

  const onForgotpasswordSubmit = async (values, resetForm) => {
    const payload = {
      email: values?.email,
    };
    postForgotPassword({ query: "", payload: payload })
      .then((res) => {
        toast.success(res?.data?.message);
        resetForm();
        navigate("/", { replace: true });
      })
      .catch((err) => {
        console.log("error", err);
        localStorage.clear();
      });
  };

  return (
    <>
      <div className="login-main-section add-back-gradient">
        <div className="top"></div>
        <div className="bottom"></div>
        <div className="container">
          <div className="row m-0 justify-content-center">
            <div className="col-xl-10 col-lg-11" >
              <div className="inner-box back-color-blue">
                <div className="row">
                  <div className="col-md-5">
                    <div className="left-side">
                      <img
                        src="/assets/images/newLogo.png"
                        className="img-fluid image"
                        alt="logo"
                      />
                      {/* <img
                        src="/assets/images/datafusion.png"
                        className="img-fluid logo"
                        alt="logo"
                      /> */}
                      <h3 className="poppins-semibold logo">
                        Data<span>Fusion</span>
                      </h3>
                      <div className="slider-common">
                        <Slider {...settings}>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/cover-photo.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Effortless EMR Integration</h3>
                              <p>
                                Connect Epic, Cerner, Athenahealth, and more -
                                all in one place.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/109.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Secure Cloud Storage</h3>
                              <p>
                                Store your patient data securely in the cloud,
                                ensuring compliance and accessibility.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/110.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Unlock Big Data Potential</h3>
                              <p>
                                Gain valuable insights through advanced
                                analytics, improving patient outcomes and
                                driving research.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/111.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Empower Informed Decisions</h3>
                              <p>
                                Make data-driven choices for better patient
                                care, resource allocation, and population health
                                management.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/112.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Simplify Complex Data Workflow</h3>
                              <p>
                                Our user-friendly platform streamlines data
                                access and analysis, saving you valuable time
                                and resources.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/113.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Future-proof Your Practice</h3>
                              <p>
                                Embrace the power of data to stay ahead of the
                                curve and deliver exceptional patient care.
                              </p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 d-flex align-items-stretch back-color-white">
                    <div className="d-flex align-items-center w-100">
                      <div className="right-side w-100">
                        <div className="login-head-top">
                          <h2>
                            {showForgotPassword
                              ? "Retrieve Your Data-Fusion Access"
                              : "Dive into Data-Fusion"}
                          </h2>
                          <p>
                            {showForgotPassword
                              ? " Quickly reset your password to continue."
                              : "Unlock the power of your EMR data."}
                          </p>
                        </div>

                        {showForgotPassword ? (
                          <Formik
                            initialValues={{ email: "" }}
                            onSubmit={(values, { resetForm }) =>
                              onForgotpasswordSubmit(values, resetForm)
                            }
                            // validationSchema={forgotPasswordSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                          >
                            {({
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              values,
                              errors,
                              touched,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i
                                        className="fa fa-envelope"
                                        aria-hidden="true"
                                      />{" "}
                                    </span>
                                    <InputField
                                      className={"did-floating-input"}
                                      classNameLabel={
                                        "did-floating-label did-floating-labe-astrisk"
                                      }
                                      type="email"
                                      name="email"
                                      label={"Enter your email"}
                                      value={values.email}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {errors.email && touched.email ? (
                                    <div className="error-message">
                                      {errors.email}
                                    </div>
                                  ) : null}
                                </div>
                                <button
                                  type="submit"
                                  className="common-btn w-100"
                                >
                                  Submit
                                </button>
                                <p className="forgot-link text-end mt-2">
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => setShowForgotPassword(false)}
                                  >
                                    Back to Login
                                  </button>
                                </p>
                              </form>
                            )}
                          </Formik>
                        ) : (
                          <Formik
                            initialValues={{ email: "", password: "" }}
                            onSubmit={(values, { resetForm }) =>
                              onLoginSubmit(values, resetForm)
                            }
                            validationSchema={loginSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                          >
                            {({
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              values,
                              errors,
                              touched,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      <i
                                        className="fa fa-envelope"
                                        aria-hidden="true"
                                      />{" "}
                                    </span>
                                    <InputField
                                      className={"did-floating-input"}
                                      classNameLabel={
                                        "did-floating-label did-floating-labe-astrisk"
                                      }
                                      type="email"
                                      name="email"
                                      label={"Enter your email"}
                                      value={values.email}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {errors?.email && touched?.email ? (
                                    <div className="error-message">
                                      {errors?.email}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <div className=" input-group">
                                    <span className="input-group-text">
                                      <i
                                        className="fa fa-lock"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <InputField
                                      className={"did-floating-input"}
                                      classNameLabel={
                                        "did-floating-label did-floating-labe-astrisk"
                                      }
                                      type={passwordType}
                                      name="password"
                                      label={"Enter Password"}
                                      value={values.password}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span
                                      toggle="#password-field"
                                      className={icon}
                                      onClick={() => _changeIcon()}
                                    ></span>
                                  </div>
                                  {errors.password && touched.password ? (
                                    <div className="error-message">
                                      {errors.password}
                                    </div>
                                  ) : null}
                                </div>
                                <p className="forgot-link text-end">
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => setShowForgotPassword(true)}
                                  >
                                    Forgot Password?
                                  </button>
                                </p>
                                <button
                                  type="submit"
                                  className="common-btn w-100"
                                >
                                  {" "}
                                  Login
                                </button>
                              </form>
                            )}
                          </Formik>
                        )}
                        <div className="pt-3 d-flex justify-content-center">
                          <small
                            className="text-center"
                            style={{ fontWeight: "bold" }}
                          >
                            Don’t have an account?{" "}
                            <Link
                              style={{ textDecoration: "underline" }}
                              to={`/register`}
                            >
                              Sign Up
                            </Link>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner
        isLoading={isLoginFetching || isLoginLoading || isLoading || isFetching}
      />
    </>
  );
};

export default Login;
