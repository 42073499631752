import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import InputField from "../../components/elements/inputs/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostResetPasswordMutation } from "../../services/apiService";
import CryptoJS from "crypto-js";

import { Formik } from "formik";
import { toast } from "react-toastify";
import {
  REACT_APP_BACKEND_URL,
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import Spinner from "../../components/elements/Spinner/Spinner";
import { resetPasswordSchema } from "../../utils/validationSchema/validation";

const ResetPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const [
    postResetPassword,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePostResetPasswordMutation();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const [newPasswordIcon, setNewPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [confirmNewPasswordIcon, setConfirmNewPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );

  const togglePasswordVisibility = (type, setType, icon, setIcon) => {
    if (type === "password") {
      setType("text");
      setIcon("fa fa-fw fa-eye field-icon toggle-password");
    } else {
      setType("password");
      setIcon("fa fa-fw fa-eye-slash field-icon toggle-password");
    }
  };

  useEffect(() => {
    const userToken = urlParams?.get("token");
    if (userToken === null || userToken === undefined) {
      toast.error("This link is invalid");
      navigate("/login", { replace: true });
    }
    if (userToken !== null && userToken !== undefined) {
      const encryptedToken = CryptoJS.AES.encrypt(
        userToken,
        REACT_APP_CRYPTOJS_SECRETKEY,
        { iv: REACT_APP_CRYPTOJS_VECTOR }
      ).toString();

      localStorage.setItem("accessToken", encryptedToken);
    }
  }, []);

  const onSubmit = async (values, resetForm) => {
    const payload = {
      password: values?.confirmNewPassword,
    };

    postResetPassword({ query: "", payload: payload })
      .then((res) => {
        if (res?.data) {
          toast.success(res?.data?.message);
          localStorage.clear();
          navigate(`/login`);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div className="login-main-section add-back-gradient">
        <div className="top"></div>
        <div className="bottom"></div>
        <div className="container">
          <div className="row m-0 justify-content-center">
            <div className="col-lg-11 col-md-12">
              <div className="inner-box">
                <div className="row align-items-center">
                  <div className="col-md-5 ">
                    <div className="left-side">
                      <img
                        src="/assets/images/newLogo.png"
                        className="img-fluid image"
                        alt="logo"
                      />
                      <h3 className="poppins-semibold logo">
                        Data-<span>fusion</span>
                      </h3>
                      <div className="slider-common">
                        <Slider {...settings}>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/cover-photo.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Effortless EMR Integration</h3>
                              <p>
                                Connect Epic, Cerner, Athenahealth, and more -
                                all in one place.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/109.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Secure Cloud Storage</h3>
                              <p>
                                Store your patient data securely in the cloud,
                                ensuring compliance and accessibility.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/110.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Unlock Big Data Potential</h3>
                              <p>
                                Gain valuable insights through advanced
                                analytics, improving patient outcomes and
                                driving research.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/111.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Empower Informed Decisions</h3>
                              <p>
                                Make data-driven choices for better patient
                                care, resource allocation, and population health
                                management.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/112.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Simplify Complex Data Workflow</h3>
                              <p>
                                Our user-friendly platform streamlines data
                                access and analysis, saving you valuable time
                                and resources.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/113.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Future-proof Your Practice</h3>
                              <p>
                                Embrace the power of data to stay ahead of the
                                curve and deliver exceptional patient care.
                              </p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="right-side">
                      <div className="login-head-top">
                        <h2>Secure Your Data-Fusion Account</h2>
                        <p>
                          Update your password to maintain the integrity of your
                          EMR data.
                        </p>
                      </div>

                      <Formik
                        initialValues={{
                          newPassword: "",
                          confirmNewPassword: "",
                        }}
                        onSubmit={(values, { resetForm }) =>
                          onSubmit(values, resetForm)
                        }
                        validationSchema={resetPasswordSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                      >
                        {({
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                          resetForm,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="col-lg-12 col-md-6">
                              <div className="mb-3">
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-lock"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type={newPasswordType}
                                    name="newPassword"
                                    label={"New Password"}
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <span
                                    className={newPasswordIcon}
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        newPasswordType,
                                        setNewPasswordType,
                                        newPasswordIcon,
                                        setNewPasswordIcon
                                      )
                                    }
                                  ></span>
                                </div>
                                {errors.newPassword && touched.newPassword ? (
                                  <div className="error-message">
                                    {errors.newPassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                              <div className="mb-3 ">
                                <div className="input-group">
                                  <span className="input-group-text">
                                    <i
                                      className="fa fa-lock"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    classNameLabel={
                                      "did-floating-label did-floating-labe-astrisk"
                                    }
                                    type={confirmNewPasswordType}
                                    name="confirmNewPassword"
                                    label={"Confirm New Password"}
                                    value={values.confirmNewPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <span
                                    className={confirmNewPasswordIcon}
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        confirmNewPasswordType,
                                        setConfirmNewPasswordType,
                                        confirmNewPasswordIcon,
                                        setConfirmNewPasswordIcon
                                      )
                                    }
                                  ></span>
                                </div>

                                {errors.confirmNewPassword &&
                                touched.confirmNewPassword ? (
                                  <div className="error-message">
                                    {errors.confirmNewPassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <button type="submit" className="common-btn w-100">
                              {" "}
                              Submit
                            </button>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isLoading} />
    </>
  );
};

export default ResetPassword;
