import React, { useState, useEffect } from "react";
import Spinner from "../../../components/elements/Spinner/Spinner";
import InputField from "../../../components/elements/inputs/InputField";
import { Formik } from "formik";
import Select, { components } from "react-select";
import CryptoJS from "crypto-js";
import { decodeToken } from "react-jwt";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../../config/config";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { REVERSE_ROLES } from "../../../utils/commonFunction";
import { BasicDetailSchemaForAdminAndUser } from "../../../utils/validationSchema/validation";
import { usePutUserUpdateProfileMutation } from "../../../services/apiService";

const MySwal = withReactContent(Swal);
const countryCode = require("../../../utils/data/CountryCodes.json");

const colourStylesCountry = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    minHeight: "auto",
    minWidth: "39px",
    width: "40px",
    padding: "0px",

    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    minHeight: "30px",

    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const BasicDetails = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    f_name: "",
    l_name: "",
    m_name: "",
    email: "",
    country_code: "",
    mobile: "",
  });

  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const data = token && decodeToken(token);
  const role = data?.user && REVERSE_ROLES[data?.user?.role];

  const [
    putUserUpdateProfile,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePutUserUpdateProfileMutation();

  useEffect(() => {
    const encryptedOrg = localStorage.getItem("org_data");

    if (encryptedOrg) {
      try {
        const decryptedOrg = CryptoJS.AES.decrypt(
          encryptedOrg,
          process.env.REACT_APP_CRYPTOJS_SECRETKEY,
          {
            iv: process.env.REACT_APP_CRYPTOJS_VECTOR,
          }
        ).toString(CryptoJS.enc.Utf8);

        const orgData = JSON.parse(decryptedOrg);
        console.log("Decrypted org_data:", orgData);

        setInitialValues((prevValues) => ({
          f_name: orgData?.f_name,
          l_name: orgData?.l_name,
          m_name: orgData?.m_name,
          country_code:
            countryCode?.find(
              (el) => el.label === orgData?.mobile?.split("-")[0]
            )?.label || "",
          mobile: orgData?.mobile?.split("-")[1] || "",
          email: orgData?.email || "",
        }));
      } catch (error) {
        console.error("Error decrypting org_data:", error);
      }
    }
  }, []);

  const onSubmit = async (values, resetForm) => {
    const payload = {
      f_name: values?.f_name,
      m_name: values?.m_name,
      l_name: values?.l_name,
      email: values?.email,
      mobile: `${values?.country_code}-${values?.mobile}`,
    };

    putUserUpdateProfile({ query: "", payload: payload }).then((res) => {
      if (res?.data?.user_data) {
        const encrypteOrg = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.user_data),
          REACT_APP_CRYPTOJS_SECRETKEY,
          { iv: REACT_APP_CRYPTOJS_VECTOR }
        ).toString();

        localStorage.setItem("org_data", encrypteOrg);
        MySwal.fire({
          title: "Success",
          text: "Profile Updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        navigate(`/${role?.toLowerCase()}/home`, { replace: true });
      }
    });
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-7 col-md-12">
          <div className="right-side">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={BasicDetailSchemaForAdminAndUser}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                touched,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  {console.log("values", values)}
                  {console.log("errors", errors)}
                  <h4 className="login-heading mb-3">User Information</h4>
                  <div className="row mb-0">
                    <div className="col-lg-4 col-md-4">
                      <div>
                        <div className="mb-3">
                          <div className=" input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="f_name"
                              label={"First Name"}
                              value={values.f_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.f_name && touched?.f_name ? (
                            <div className="error-message">
                              {errors?.f_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div>
                        <div className="mb-3">
                          <div className="input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={"did-floating-label"}
                              type="text"
                              name="m_name"
                              label={"Middle Name"}
                              value={values?.m_name || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.m_name && touched?.m_name ? (
                            <div className="error-message">
                              {errors?.m_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div>
                        <div className="mb-3">
                          <div className=" input-group">
                            <span className="input-group-text">
                              <i className="fa fa-user" aria-hidden="true" />{" "}
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="l_name"
                              label={"Last Name"}
                              value={values.l_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors?.l_name && touched?.l_name ? (
                            <div className="error-message">
                              {errors?.l_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-0">
                    <div className="col-lg-6 col-md-6">
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-envelope" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="email"
                            name="email"
                            label={"Email"}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.email && touched?.email ? (
                          <div className="error-message">{errors?.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div>
                        <div className="mb-3">
                          <div className="input-group  d-flex align-items-center flex-nowrap">
                            <span
                              className="input-group-text p-0"
                              style={{ width: "auto" }}
                              id="basic-addon1"
                            >
                              <Select
                                onChange={(e) =>
                                  setFieldValue("country_code", e.label)
                                }
                                placeholder={""}
                                defaultValue={{
                                  label: "+1",
                                  value: "CA",
                                }}
                                readOnly={true}
                                styles={colourStylesCountry}
                                isSearchable={true}
                                options={countryCode}
                                value={countryCode.find(
                                  (option) =>
                                    option.label === values?.country_code
                                )}
                                components={{
                                  Control,
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </span>
                            <InputField
                              className={"did-floating-input"}
                              classNameLabel={
                                "did-floating-label did-floating-labe-astrisk"
                              }
                              type="text"
                              name="mobile"
                              label={"Contact"}
                              value={
                                values?.mobile
                                  ?.replace(/[^\d]/g, "")
                                  .replace(
                                    /(\d{3})(\d{3})(\d{4})/,
                                    "$1-$2-$3"
                                  ) || ""
                              }
                              maxLength={12}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {(errors.mobile && touched.mobile) ||
                          (errors?.country_code && touched.country_code) ? (
                            <div className="error-message">
                              {errors.mobile || errors?.country_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex pb-1">
                    <span>
                      <label className="label check-terms">
                        {" "}
                        <input
                          type="checkbox"
                          required
                          style={{ marginTop: "0px" }}
                          className="form-check-input"
                          id="remember_me"
                          name="remember_me"
                          value="remember_me"
                        />{" "}
                        I agree to Data-Fusion
                        <a
                          href="https://localhost:3000/terms-and-conditions"
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#50B5FF",
                          }}
                        >
                          T&C
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://localhost:3000/privacy-policy"
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#50B5FF",
                          }}
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </span>
                  </div>
                  <button type="submit" className="common-btn w-100">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Spinner isLoading={isFetching || isLoading} />
    </div>
  );
};

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

export default BasicDetails;
