import React, { useState } from "react";
import { Formik } from "formik";
import InputField from "../../components/elements/inputs/InputField";
import MasterUserLayout from "../../components/MasterUser/MasterUserLayout";
import { usePostUserChangePasswordMutation } from "../../services/apiService";
import { changePasswordSchema } from "../../utils/validationSchema/validation";
import Spinner from "../../components/elements/Spinner/Spinner";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { REVERSE_ROLES } from "../../utils/commonFunction";

const MySwal = withReactContent(Swal);

const MasterUserChangePassword = () => {
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");
  const encryptedToken = localStorage.getItem("accessToken");
  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);
  const data = token && decodeToken(token);
  const role = data?.user && REVERSE_ROLES[data?.user?.role];

  const [oldPasswordIcon, setOldPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [newPasswordIcon, setNewPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [confirmNewPasswordIcon, setConfirmNewPasswordIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );

  const togglePasswordVisibility = (type, setType, icon, setIcon) => {
    if (type === "password") {
      setType("text");
      setIcon("fa fa-fw fa-eye field-icon toggle-password");
    } else {
      setType("password");
      setIcon("fa fa-fw fa-eye-slash field-icon toggle-password");
    }
  };

  const navigate = useNavigate();
  const [
    postUserChangePassword,
    { isLoading, isSuccess, isError, error, isFetching },
  ] = usePostUserChangePasswordMutation();

  const onSubmit = async (values, resetForm) => {
    const payload = {
      old_password: values?.oldPassword,
      new_password: values?.confirmNewPassword,
    };

    postUserChangePassword({ query: "", payload: payload })
      .then((res) => {
        if (res?.data?.message) {
          MySwal.fire({
            title: "Success",
            text: "Password changed successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          resetForm();
          navigate(`/${role?.toLowerCase()}/home`, { replace: true });
        }
      })
      .catch((err) => {
        console.log("error", err);
        localStorage.clear();
        navigate("/login", { replace: true });
      });
  };

  return (
    <>
      <MasterUserLayout>
        <div className="overview-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <h4>Change Password</h4>
                <div className="white-box">
                  <div className="right-side">
                    <Formik
                      initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmNewPassword: "",
                      }}
                      onSubmit={(values, { resetForm }) =>
                        onSubmit(values, resetForm)
                      }
                      validationSchema={changePasswordSchema}
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        resetForm,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  type={oldPasswordType}
                                  name="oldPassword"
                                  label={"Old Password"}
                                  value={values.oldPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span
                                  className={oldPasswordIcon}
                                  onClick={() =>
                                    togglePasswordVisibility(
                                      oldPasswordType,
                                      setOldPasswordType,
                                      oldPasswordIcon,
                                      setOldPasswordIcon
                                    )
                                  }
                                ></span>
                              </div>
                              {errors.oldPassword && touched.oldPassword ? (
                                <div className="error-message">
                                  {errors.oldPassword}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  type={newPasswordType}
                                  name="newPassword"
                                  label={"New Password"}
                                  value={values.newPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span
                                  className={newPasswordIcon}
                                  onClick={() =>
                                    togglePasswordVisibility(
                                      newPasswordType,
                                      setNewPasswordType,
                                      newPasswordIcon,
                                      setNewPasswordIcon
                                    )
                                  }
                                ></span>
                              </div>
                              {errors.newPassword && touched.newPassword ? (
                                <div className="error-message">
                                  {errors.newPassword}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 ">
                              <div className=" input-group">
                                <span className="input-group-text">
                                  <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                  />
                                </span>
                                <InputField
                                  className={"did-floating-input"}
                                  type={confirmNewPasswordType}
                                  name="confirmNewPassword"
                                  label={"Confirm New Password"}
                                  value={values.confirmNewPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span
                                  className={confirmNewPasswordIcon}
                                  onClick={() =>
                                    togglePasswordVisibility(
                                      confirmNewPasswordType,
                                      setConfirmNewPasswordType,
                                      confirmNewPasswordIcon,
                                      setConfirmNewPasswordIcon
                                    )
                                  }
                                ></span>
                              </div>
                              {errors.confirmNewPassword &&
                              touched.confirmNewPassword ? (
                                <div className="error-message">
                                  {errors.confirmNewPassword}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <button type="submit" className="common-btn w-100">
                            Submit
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spinner isLoading={isLoading || isFetching} />
      </MasterUserLayout>
    </>
  );
};

export default MasterUserChangePassword;
