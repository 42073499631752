import {
  configureStore,
  combineReducers,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import {
  organizationAPI,
  serverAPI,
  userAPI,
  adminAPI,
  customerAPI,
} from "../services/apiService";
import { toast } from "react-toastify";
import userSlice from "./slice/userSlice";
import organizationSlice from "./slice/organizationSlice";
import customerSlice from "./slice/customerSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  organization: organizationSlice,
  customer: customerSlice,
  [serverAPI.reducerPath]: serverAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [organizationAPI.reducerPath]: organizationAPI.reducer,
  [adminAPI.reducerPath]: adminAPI.reducer,
  [customerAPI.reducerPath]: adminAPI.reducer,
});

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // console.log("action", action);

  if (isRejectedWithValue(action) || action?.meta?.rejectedWithValue === true) {
    let errorMsg =
      action.payload.error ||
      action.payload?.data?.message ||
      action.payload.data?.responseMessage ||
      action?.payload?.data?.statusText;
    if (errorMsg) {
      toast.error(errorMsg);
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(serverAPI.middleware)
      .concat(organizationAPI.middleware)
      .concat(userAPI.middleware)
      .concat(adminAPI.middleware)
      .concat(customerAPI.middleware)
      .concat(rtkQueryErrorLogger),
});

export default store;
