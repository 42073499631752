import React, { useState } from "react";
import { Formik } from "formik";
import InputField from "../elements/inputs/InputField";
import Select, { components } from "react-select";
import countryCode from "../../utils/data/CountryCodes.json";
import { usePostAddOrganizationsDataForAdminMutation } from "../../services/apiService";
import Spinner from "../elements/Spinner/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { addOrganizationSchema } from "../../utils/validationSchema/validation";
import { toast } from "react-toastify";

const MySwal = withReactContent(Swal);

const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "none",
    boxShadow: "none",
    fontSize: "14px",
    minHeight: "auto",
    minWidth: "39px",
    width: "40px",
    padding: "0px",

    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 1999,
    minWidth: "53px",
    minHeight: "30px",
  }),
  menuList: (base) => ({
    ...base,
    height: "200px",
    minHeight: "30px",

    "::-webkit-scrollbar": {
      width: "2px",
    },
    "::-webkit-scrollbar-track": {
      background: "white",
    },
    "::-webkit-scrollbar-thumb": {
      background: "var(--wp--preset--color--primary-blue)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};

const AddOrganizationModal = ({
  closeAddOrganizationModal,
  onOrganizationAdded,
}) => {
  const [org_logo, setOrgLogo] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarError, setAvatarError] = useState("");

  const [
    postAddOrganizationsDataForAdmin,
    { data, isLoading, isSuccess, isError, error, isFetching },
  ] = usePostAddOrganizationsDataForAdminMutation();

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1.5 * 1024 * 1024) {
      // Validate file format here
      if (file.type === "image/png") {
        setOrgLogo(file);
        setAvatarPreview(URL.createObjectURL(file));
        setAvatarError("");
      } else {
        setAvatarError("File must be in PNG format.");
      }
    } else {
      setAvatarError("File size must be less than 1.5 MB");
    }
  };

  const handleAddOrganization = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("org_name", values.org_name);
    formData.append(
      "org_mobile",
      values?.org_mobile ? values?.country_code + "-" + values?.org_mobile : ""
    );
    formData.append(
      "mobile",
      values?.mobile ? values?.c_code + "-" + values?.mobile : ""
    );
    formData.append("org_url", values.org_url);
    formData.append("org_email", values.org_email);
    formData.append("org_address", values.org_address);
    formData.append("org_description", values.org_description);
    formData.append("f_name", values.f_name);
    formData.append("m_name", values.m_name);
    formData.append("l_name", values.l_name);

    console.log("org_logo", org_logo);

    if (org_logo) {
      formData.append("org_logo", org_logo);
    }

    postAddOrganizationsDataForAdmin({ query: "", payload: formData })
      .then((res) => {
        if (res?.data) {
          MySwal.fire({
            title: "Success",
            text: "User Details Added successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            resetForm();
            onOrganizationAdded();
            closeAddOrganizationModal();
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div className="modal-dialog  modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="addUserModal">
            Add Organization Details
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeAddOrganizationModal}
          />
        </div>
        <div className="modal-body right-side">
          <Formik
            initialValues={{
              f_name: "",
              m_name: "",
              l_name: "",
              mobile: "",
              org_name: "",
              org_email: "",
              org_description: "",
              org_address: "",
              org_url: "",
              org_mobile: "",
              country_code: "+1",
              c_code: "+1",
            }}
            onSubmit={(values, { resetForm }) => {
              if (!avatarError) {
                handleAddOrganization({ ...values, org_logo }, resetForm);
              } else {
                toast.error(avatarError);
              }
            }}
            validationSchema={addOrganizationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              touched,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log("values", values)}
                {console.log("errors", errors)}
                <h4 className="login-heading">User Information</h4>
                <div className="row mb-0">
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="f_name"
                            label={"First Name"}
                            value={values.f_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.f_name && touched?.f_name ? (
                          <div className="error-message">{errors?.f_name}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={"did-floating-label"}
                            type="text"
                            name="m_name"
                            label={"Middle Name"}
                            value={values?.m_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.m_name && touched?.m_name ? (
                          <div className="error-message">{errors?.m_name}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-0">
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="l_name"
                            label={"Last Name"}
                            value={values.l_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.l_name && touched?.l_name ? (
                          <div className="error-message">{errors?.l_name}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className="input-group  d-flex align-items-center flex-nowrap">
                          <span
                            className="input-group-text p-0"
                            style={{ width: "auto" }}
                            id="basic-addon1"
                          >
                            <Select
                              onChange={(e) => setFieldValue("c_code", e.label)}
                              placeholder={""}
                              readOnly={true}
                              styles={colourStyles}
                              isSearchable={true}
                              options={countryCode}
                              defaultValue={{
                                label: "+1",
                                value: "CA",
                              }}
                              components={{
                                Control,
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={"did-floating-label"}
                            type="text"
                            name="mobile"
                            label={"User Contact"}
                            value={
                              values?.mobile
                                ?.replace(/[^\d]/g, "")
                                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ||
                              ""
                            }
                            maxLength={12}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {(errors.mobile && touched.mobile) ||
                        (errors?.c_code && touched.c_code) ? (
                          <div className="error-message">
                            {errors.mobile || errors?.c_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <h4 className="login-heading">Business Information</h4>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-envelope" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="email"
                            name="org_email"
                            label={"Company Email"}
                            value={values.org_email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors?.org_email && touched?.org_email ? (
                          <div className="error-message">
                            {errors?.org_email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3">
                      <div className="input-group  d-flex align-items-center flex-nowrap">
                        <span
                          className="input-group-text p-0"
                          style={{ width: "auto" }}
                          id="basic-addon1"
                        >
                          <Select
                            onChange={(e) =>
                              setFieldValue("country_code", e.label)
                            }
                            placeholder={""}
                            readOnly={true}
                            styles={colourStyles}
                            isSearchable={true}
                            options={countryCode}
                            defaultValue={{
                              label: "+1",
                              value: "CA",
                            }}
                            components={{
                              Control,
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </span>
                        <InputField
                          className={"did-floating-input"}
                          classNameLabel={
                            "did-floating-label did-floating-labe-astrisk"
                          }
                          type="text"
                          name="org_mobile"
                          label={"Organization Contact"}
                          value={
                            values?.org_mobile
                              ?.replace(/[^\d]/g, "")
                              .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ||
                            ""
                          }
                          maxLength={12}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      {(errors.org_mobile && touched.org_mobile) ||
                      (errors?.country_code && touched.country_code) ? (
                        <div className="error-message">
                          {errors.org_mobile || errors?.country_code}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-building" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="org_name"
                            label={"Company Name"}
                            value={values.org_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.org_name && touched.org_name ? (
                          <div className="error-message">{errors.org_name}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div>
                      <div className="mb-3">
                        <div className=" input-group">
                          <span className="input-group-text">
                            <i className="fa fa-link" aria-hidden="true" />{" "}
                          </span>
                          <InputField
                            className={"did-floating-input"}
                            classNameLabel={
                              "did-floating-label did-floating-labe-astrisk"
                            }
                            type="text"
                            name="org_url"
                            label={"Organization's website"}
                            value={values.org_url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                        {errors.org_url && touched.org_url ? (
                          <div className="error-message">{errors.org_url}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3 input-group">
                      <span className="input-group-text">
                        <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                      </span>
                      <InputField
                        className={"did-floating-input"}
                        type="text"
                        name="org_address"
                        label={"Organization Address"}
                        value={values.org_address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-3 input-group">
                      <span className="input-group-text">
                        <i className="fa fa-info-circle" aria-hidden="true" />{" "}
                      </span>
                      <InputField
                        className={"did-floating-input"}
                        type="text"
                        name="org_description"
                        label={"Organization Description"}
                        value={values.org_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="mb-3 input-group">
                      <span className="input-group-text">
                        <i className="fa fa-image" aria-hidden="true" />{" "}
                      </span>
                      <input
                        type="file"
                        accept=".png"
                        onChange={handleAvatarChange}
                        className="form-control"
                      />
                    </div>
                    {(avatarPreview || org_logo) && (
                      <div className="mb-3">
                        <img
                          src={
                            avatarPreview ||
                            process.env.REACT_APP_FILE_URL + values?.org_logo
                          }
                          alt="Avatar Preview"
                          style={{ maxHeight: "100px" }}
                        />
                      </div>
                    )}
                    {avatarError ? (
                      <div className="error-message">{avatarError}</div>
                    ) : (
                      <div
                        style={{
                          color: "#ff5f15",
                          marginTop: "0px",
                          fontWeight: "normal",
                          fontSize: "12px",
                        }}
                      >
                        File size should not exceed 1.5mb.
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex pb-1">
                  <span>
                    <label className="label check-terms">
                      {" "}
                      <input
                        type="checkbox"
                        required
                        style={{ marginTop: "0px" }}
                        className="form-check-input"
                        id="remember_me"
                        name="remember_me"
                        value="remember_me"
                      />{" "}
                      I agree to Data-Fusion
                      <a
                        href="https://localhost:3000/terms-and-conditions"
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          color: "#50B5FF",
                        }}
                      >
                        T&C
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://localhost:3000/privacy-policy"
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          color: "#50B5FF",
                        }}
                      >
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </span>
                </div>

                <button type="submit" className="common-btn w-100">
                  Submit
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Spinner isLoading={isFetching || isLoading} />
    </div>
  );
};

export default AddOrganizationModal;
export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
