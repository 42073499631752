import { useLocation, Navigate, Outlet } from "react-router-dom";
import React from "react";
import useAuth from "../../hooks/useAuth";
import { REVERSE_ROLES } from "../../utils/commonFunction";

const ProtectedRoutes = ({ allowedRole }) => {
  const location = useLocation();
  const { role, isOtpVerified } = useAuth();

  if (role && REVERSE_ROLES[allowedRole] === role && isOtpVerified) {
    return <Outlet />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoutes;
