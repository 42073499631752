import { ROLES } from "../config/roles";

export const firstCapitalLetter = (name) => {
  return name?.[0].slice("").toUpperCase() + name?.slice(1).toLowerCase();
};

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function limitString(str, maxLength = 10) {
  if (!str) {
    return "-N/A-";
  }
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

export const REVERSE_ROLES = Object.fromEntries(
  Object.entries(ROLES).map(([key, value]) => [value, key])
);

export function smoothScrollTo(element) {
  const rect = element.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  window.scrollTo({
    top: rect.top + scrollTop,
    behavior: "smooth",
  });
}
