import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer-common">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ul className="social-foot ">
                <li>
                  <a href="" target="_blank" title="instagram" rel="nofollow">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="facebook" rel="nofollow">
                    <i className="fab fa-facebook-f" />
                  </a>{" "}
                </li>
                <li>
                  <a href="" target="_blank" title="twitter" rel="nofollow">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="linkedin" rel="nofollow">
                    <i className="fab fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="whatsapp" rel="nofollow">
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="telegram" rel="nofollow">
                    <i className="fab fa-telegram-plane" rel="nofollow" />
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="youtube" rel="nofollow">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="discord" rel="nofollow">
                    <i className="fab fa-discord" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <div>
                <p className="copyright-sec">
                  Copyright © 2024 Santeware HealthCare Solutions All rights
                  reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
