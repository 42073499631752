import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { REVERSE_ROLES } from "../../utils/commonFunction";
import Spinner from "../../components/elements/Spinner/Spinner";
import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../config/config";
import {
  usePostOtpRequestMutation,
  usePostVerifyOtpMutation,
} from "../../services/apiService";
import { decodeToken } from "react-jwt";
import { verifyOtp } from "../../redux/slice/authSlice";

const OtpVerificationschema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required")
    .length(6, "OTP must be 6 digits"),
});

const VerifyOTP = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [ipAddress, setIPAddress] = useState("");

  const [deviceType, setDeviceType] = useState("Unknown");
  const [browserInfo, setBrowserInfo] = useState("Unknown");

  const email = location?.state?.email;

  const [resendButtonLoading, setResendButtonLoading] = useState(false);

  const [
    postOtpRequest,
    { isLoading: isOtpLoading, isFetching: isOtpFetching },
  ] = usePostOtpRequestMutation();
  const [postVerifyOtp, { isLoading, isFetching }] = usePostVerifyOtpMutation();

  const resendVerificationCode = async () => {
    setResendButtonLoading(true);
    try {
      await postOtpRequest({ params: "", payload: { email: email } });
      setResendButtonLoading(false);
    } catch (err) {
      setResendButtonLoading(false);
    }
  };

  useEffect(() => {
    // Fetch IP address from a different API
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        console.log("IP Address Data:", data); // Log the data
        setIPAddress(data?.ip || "N/A");
      })
      .catch((error) => console.error("Failed to fetch IP address:", error));

    // Detect device type based on user agent
    const userAgent = navigator.userAgent;
    const mobileDeviceRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const tabletDeviceRegex = /iPad/i;

    if (mobileDeviceRegex.test(userAgent)) {
      setDeviceType("Mobile");
    } else if (tabletDeviceRegex.test(userAgent)) {
      setDeviceType("Tablet");
    } else {
      setDeviceType("Desktop");
    }

    // Detect browser name and version
    const browserName = navigator.appName;
    const browserVersion = navigator.appVersion;
    setBrowserInfo(`${browserName} ${browserVersion}`);
  }, []);

  useEffect(() => {
    if (email) {
      postOtpRequest({ params: "", payload: { email: email } });
    }
  }, [email]);

  if (!location?.state) {
    localStorage.clear();
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const onSubmit = async (values, resetForm) => {
    console.log("values", values);

    const payload = {
      otp: values?.otp,
      device_browser: browserInfo || "",
      device_type: deviceType || "",
      device_ip: ipAddress || "",
      device_token: "",
    };

    postVerifyOtp({ query: "", payload: payload })
      .then((res) => {
        if (res?.data?.isUserVerified) {
          const userData = decodeToken(res?.data?.token);

          const encryptedToken = CryptoJS.AES.encrypt(
            res?.data?.token,
            REACT_APP_CRYPTOJS_SECRETKEY,
            { iv: REACT_APP_CRYPTOJS_VECTOR }
          ).toString();

          const encryptedOrg = CryptoJS.AES.encrypt(
            JSON.stringify(res?.data?.user_data),
            REACT_APP_CRYPTOJS_SECRETKEY,
            { iv: REACT_APP_CRYPTOJS_VECTOR }
          ).toString();

          localStorage.setItem("accessToken", encryptedToken);
          localStorage.setItem("isUserVerified", res?.data?.isUserVerified);
          localStorage.setItem("org_data", encryptedOrg);

          const role = REVERSE_ROLES[userData?.user?.role];

          toast.success("Authentication successful");
          dispatch(verifyOtp());
          navigate(`/${role?.toLowerCase()}/home`);
        }
      })
      .catch((err) => {
        console.log("error", err);
        localStorage.clear();
        navigate("/login", {
          state: {
            email: values?.email,
          },
        });
      });
  };

  return (
    <>
      <div className="login-main-section add-back-gradient">
        <div className="top"></div>
        <div className="bottom"></div>
        <div className="container">
          <div className="row m-0 justify-content-center">
            <div className="col-lg-10 col-md-10">
              <div className="inner-box">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="left-side">
                      <img
                        src="/assets/images/newLogo.png"
                        className="img-fluid image"
                        alt="logo"
                      />
                      <h3 className="poppins-semibold logo">
                        Data-<span>fusion</span>
                      </h3>
                      <div className="slider-common">
                        <Slider {...settings}>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/cover-photo.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Effortless EMR Integration</h3>
                              <p>
                                Connect Epic, Cerner, Athenahealth, and more -
                                all in one place.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/109.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Secure Cloud Storage</h3>
                              <p>
                                Store your patient data securely in the cloud,
                                ensuring compliance and accessibility.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/110.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Unlock Big Data Potential</h3>
                              <p>
                                Gain valuable insights through advanced
                                analytics, improving patient outcomes and
                                driving research.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/111.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Empower Informed Decisions</h3>
                              <p>
                                Make data-driven choices for better patient
                                care, resource allocation, and population health
                                management.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/112.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Simplify Complex Data Workflow</h3>
                              <p>
                                Our user-friendly platform streamlines data
                                access and analysis, saving you valuable time
                                and resources.
                              </p>
                            </div>
                          </div>
                          <div>
                            <div className="slide-content">
                              <img
                                src="/assets/images/113.png"
                                className="img-fluid"
                                alt="health"
                              />
                              <h3>Future-proof Your Practice</h3>
                              <p>
                                Embrace the power of data to stay ahead of the
                                curve and deliver exceptional patient care.
                              </p>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="right-side">
                      <div className="login-head-top">
                        <h2>Please verify security code!</h2>
                        <p>Security code has been sent to : {email}</p>
                      </div>
                      <Formik
                        initialValues={{ otp: "" }}
                        validationSchema={OtpVerificationschema}
                        onSubmit={(values, { resetForm }) =>
                          onSubmit(values, resetForm)
                        }
                      >
                        {({
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          values,
                          errors,
                          touched,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <div>
                                  <Field name="otp">
                                    {({ field, form }) => (
                                      <OTPInput
                                        value={field.value}
                                        onChange={(value) =>
                                          form.setFieldValue(field.name, value)
                                        }
                                        autoFocus
                                        className="otp_box justify-content-center"
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                        secure
                                      />
                                    )}
                                  </Field>
                                  {errors.otp && touched.otp ? (
                                    <div className="text-danger">
                                      {errors.otp}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="common-btn w-100"
                              disabled={
                                isOtpFetching ||
                                isOtpLoading ||
                                isLoading ||
                                isFetching
                              }
                            >
                              Submit
                            </button>
                            <p className="forgot-link text-end mt-2">
                              <button
                                type="button"
                                onClick={resendVerificationCode}
                                className="btn btn-link"
                                disabled={resendButtonLoading}
                              >
                                Resend Code
                              </button>
                            </p>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner
        isLoading={isOtpFetching || isOtpLoading || isLoading || isFetching}
      />
    </>
  );
};

export default VerifyOTP;
