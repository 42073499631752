import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

function UserLayout(props) {
  return (
    <React.Fragment>
      <div className="dashboard-layout">
        <Header />
        <Sidebar>{props.children}</Sidebar>
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}

export default React.memo(UserLayout);
