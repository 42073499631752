import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="add-position">
        <header className="main-header login-header-home remove-position">
          <nav className="navbar navbar-expand-lg  sticky-top">
            <div className="container">
              <Link className="navbar-brand" to="/login">
                <img
                  src="/assets/images/newLogo.png"
                  className="img-fluid"
                  alt="logo"
                />
              </Link>

              <div className="d-flex align-items-center gap-2">
                <Link to="/" className="nav-link">
                  Login
                </Link>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Header;
