import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  page_size: 25,
  search: "",
  organizationList: [],
  total_pages: 0,
  totalRecords: 0,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizationList: (state, action) => {
      state.organizationList = action.payload.organizations;
      state.totalRecords = action.payload.total_record;
      state.total_pages = Math.ceil(
        action.payload.total_record / state.page_size
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.page_size = action.payload;
      state.page = 1; // Reset to the first page
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { setOrganizationList, setPage, setPageSize, setSearch } =
  organizationSlice.actions;

export default organizationSlice.reducer;
