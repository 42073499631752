import React from "react";

const Spinner = ({ isLoading, dontClose }) => {
  return (
    isLoading && (
      <div
        className="position-fixed w-100 vh-100 top-0 start-0 d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(4px)",
          zIndex: 999,
        }}
      >
        <div
          id="spinner"
          className="position-relative"
          style={{ zIndex: 1000 }}
        >
          <div
            className="spinner-border"
            role="status"
            style={{ width: "3rem", height: "3rem", color: "#293089" }}
          ></div>
          {dontClose && (
            <div className="text-danger mt-2">
              <p>
                <strong>
                  Please do not refresh or close this window. Your action may
                  disrupt the ongoing process...
                </strong>
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Spinner;
