import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserList,
  setPage,
  setPageSize,
  setSearch,
} from "../../redux/slice/userSlice"; // Adjust the path

import MasterUserLayout from "../../components/MasterUser/MasterUserLayout";
import {
  useDeleteUserMutation,
  usePostAllUsersDataMutation,
} from "../../services/apiService";
import UpdateUserModal from "../../components/UpdateuserModal/UpdateUserModal";
import AddUserModal from "../../components/AddUserModal/AddUserModal";

import Spinner from "../../components/elements/Spinner/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Breadcrumb from "../../components/elements/Breadcrumb/Breadcrumb";
const MySwal = withReactContent(Swal);
const $ = window.$;

const MasterUserUserManagement = () => {
  const dispatch = useDispatch();
  const [postAllUsersData, { isLoading, isFetching }] =
    usePostAllUsersDataMutation();
  const { userList, page, page_size, search, total_pages } = useSelector(
    (state) => state.user
  );

  const [deleteUser, { isLoading: isDeleting, isFetching: isUserDeleting }] =
    useDeleteUserMutation();
  const [paginationLength, setPaginationLength] = useState(page_size);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const isDeletingRef = useRef(false);
  const breadcrumbItems = [{ label: "User Management" }];

  const fetchUsers = async () => {
    const payload = { page, page_size, search };
    const response = await postAllUsersData({ query: "", payload: payload });

    if (response?.data) {
      dispatch(setUserList(response.data));
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, page_size, search]);

  const handleDeleteUser = async (userId) => {
    if (isDeletingRef.current) return;
    isDeletingRef.current = true;

    try {
      const payload = { user_id: userId };
      const response = await deleteUser({ query: "", payload: payload });

      if (response?.data) {
        MySwal.fire({
          title: "Success",
          text: response?.data?.message,
          icon: "success",
          confirmButtonText: "OK",
        });

        fetchUsers();
      }
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "An error occurred while deleting the user.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      isDeletingRef.current = false;
    }
  };

  const handlePaginationLengthChange = (newPageSize) => {
    setPaginationLength(newPageSize);
    dispatch(setPageSize(newPageSize));
  };

  useEffect(() => {
    $.fn.dataTable.ext.errMode = "none";
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust();

    const renderPagination = () => {
      const table = $("#UserListTable").DataTable();
      const totalPages = total_pages;
      const currentPage = page;

      const paginationContainer = $(".dataTables_paginate");
      const paginationList = $("<ul>", { class: "pagination" });
      paginationContainer.empty().append(paginationList);

      let startPage = Math.max(1, currentPage - 2);
      let endPage = Math.min(totalPages, currentPage + 2);

      if (totalPages > 5 && currentPage <= 3) {
        endPage = 5;
      } else if (totalPages > 5 && currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }
      const firstButton = $("<a>", {
        text: "First",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(1));
            table.page("first").draw(false);
          }
        },
      });

      const previousButton = $("<a>", {
        text: "Previous",
        class: `page-link pointer ${currentPage === 1 ? "disabled" : ""}`,
        click: function () {
          if (currentPage > 1) {
            dispatch(setPage(page - 1));
            table.page("previous").draw(false);
          }
        },
      });

      const nextButton = $("<a>", {
        text: "Next",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(page + 1));
            table.page("next").draw(false);
          }
        },
      });
      const lastButton = $("<a>", {
        text: "Last",
        class: `page-link pointer ${
          currentPage === totalPages ? "disabled" : ""
        }`,
        click: function () {
          if (currentPage < totalPages) {
            dispatch(setPage(totalPages));
            table.page("last").draw(false);
          }
        },
      });

      const firstListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(firstButton);
      const previousListItem = $("<li>", {
        class: `page-item ${currentPage === 1 ? "disabled" : ""}`,
      }).append(previousButton);
      const nextListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(nextButton);
      const lastListItem = $("<li>", {
        class: `page-item ${currentPage === totalPages ? "disabled" : ""}`,
      }).append(lastButton);
      paginationList.append(firstListItem);
      paginationList.append(previousListItem);

      for (let i = startPage; i <= endPage; i++) {
        const pageLink = $("<a>", {
          text: i,
          class: `page-link pointer ${i === currentPage ? "active" : ""}`,
          click: function () {
            dispatch(setPage(i));
            table.page(i - 1).draw(false);
          },
        });

        const listItem = $("<li>", {
          class: `page-item ${i === currentPage ? "active" : ""}`,
        }).append(pageLink);
        paginationList.append(listItem);
      }

      paginationList.append(nextListItem);
      paginationList.append(lastListItem);
    };

    const table = $("#UserListTable").DataTable({
      searching: true,
      order: [],
      data: userList || [],
      columns: [
        { title: "First Name", data: "f_name" },
        { title: "Middle Name", data: "m_name" },
        { title: "Last Name", data: "l_name" },
        { title: "Email", data: "email" },
        {
          title: "Mobile",
          data: null,
          render: (data, type, row) => {
            // Check if the mobile value contains a hyphen
            if (row?.mobile?.includes("-")) {
              // Split the mobile value by the hyphen
              const [beforeHyphen, afterHyphen] = row?.mobile?.split("-") || [];
              return `${beforeHyphen}${afterHyphen}`;
            } else {
              // Handle the case where there is no hyphen
              return ` ${row?.mobile}`;
            }
          },
        },
        {
          title: "Actions",
          data: null,
          render: (data, type, row) => {
            return `
            <div style="display:flex;gap:6px">
              <button class="edit-button" data-user='${JSON.stringify(
                row
              )}'>Edit</button>
              <button class="delete-button btn btn-sm btn-danger" data-user-id="${
                row.id
              }">
                <i class="fa fa-trash"></i>
              </button>
              </div>
              `;
          },
        },
      ],
      drawCallback: function () {
        renderPagination();
      },
    });

    table.page.len(paginationLength).draw();
    $("#UserListTable").on("length.dt", function (e, settings, len) {
      handlePaginationLengthChange(len);
    });

    $("#UserListTable").on("click", ".edit-button", function () {
      const user = $(this).data("user");
      setSelectedUser(user);
      setShowUpdateUserModal(true);
    });
    $("#UserListTable").on("click", ".delete-button", function () {
      const userId = $(this).data("user-id");
      handleDeleteUser(userId);
    });

    $("#global-search").on("keyup", function () {
      dispatch(setSearch(this.value));
      table.search(this.value).draw();
    });

    $("#page-size").on("change", function () {
      const newSize = $(this).val();
      dispatch(setPageSize(parseInt(newSize)));
      table.page.len(parseInt(newSize)).draw();
    });

    return () => {
      $("#UserListTable").off("length.dt");
      table.destroy();
    };
  }, [userList, page, page_size, total_pages]);

  const openAddUserModal = () => {
    setShowAddUserModal(true);
  };

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const closeUpdateUserModal = () => {
    setShowUpdateUserModal(false);
  };

  return (
    <MasterUserLayout>
      <Breadcrumb items={breadcrumbItems} />
      <div className="overview-content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="configure-head mt-0 mb-4">
                <div className="same-heading-icon">
                  <h4>Users List</h4>
                </div>
              </div>
              <div className="date-section">
                <div className="d-flex flex-wrap gap-3">
                  <div className="select-field">
                    <select
                      id="page-size"
                      defaultValue={page_size}
                      onChange={(e) => {
                        handlePaginationLengthChange(e.target.value);
                      }}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                  <div className="search-field">
                    <div className="input-group">
                      <input
                        type="text"
                        id="global-search"
                        placeholder="Search..."
                      />
                      <div className="input-group-append">
                        <button className="btn btn-success" type="button">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="common-same-button"
                    type="button"
                    onClick={openAddUserModal}
                  >
                    Add New User
                  </button>
                </div>
              </div>
              <div className="white-box">
                <div className="common-table m-0">
                  <table
                    className="table dt-responsive table-hover nowrap table-striped list-table"
                    style={{ width: "100%" }}
                    id="UserListTable"
                  ></table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddUserModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <AddUserModal
            closeAddUserModal={closeAddUserModal}
            onUserAdded={() => {
              fetchUsers();
            }}
          />
        </div>
      )}
      {showUpdateUserModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <UpdateUserModal
            user={selectedUser}
            closeUpdateUserModal={closeUpdateUserModal}
            onUserUpdated={() => {
              fetchUsers();
            }}
          />
        </div>
      )}
      <Spinner
        isLoading={isFetching || isLoading || isDeleting || isUserDeleting}
      />
    </MasterUserLayout>
  );
};

export default MasterUserUserManagement;
