import React from "react";
import { Link } from "react-router-dom";
import UserHeader from "../../components/elements/shared/Header";
import UserFooter from "../../components/elements/shared/Footer";

const TermAndCondition = () => {
  return (
    <>
      <UserHeader />
      <div className="tnC-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 p-0">
              <div className="header-background">
                <h3>Terms and Conditions</h3>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12">
              <div className="content-1 set-margin">
                <p>
                  Data-Fusion Technologies Private Limited, on behalf of itself
                  and its affiliates/group companies under the brand "EMR
                  Connect" <b>("Data-Fusion")</b>, is the author and publisher
                  of the internet resource www.Data-Fusion.in and the mobile
                  application 'Data-Fusion' (together, "Website").
                </p>

                <h4>1.NATURE AND APPLICABILITY OF TERMS</h4>
                <p>
                  Please carefully go through these terms and conditions
                  <b>("Terms")</b> and the privacy policy available at
                  <Link to="/privacy">
                    https://www.Data-Fusion.in/company/privacy
                  </Link>
                  <b> ("Privacy Policy") </b>
                  before you decide to access the Website or avail the services
                  made available on the Website by Data-Fusion. These Terms and
                  the Privacy Policy together constitute a legal agreement
                  <b>("Agreement")</b> between you and Data-Fusion in connection
                  with your visit to the Website and your use of the Services
                  (as defined below).
                </p>
                <p>The Agreement applies to you whether you are -</p>
                <ul>
                  <p>
                    . A medical practitioner or health care provider (whether an
                    individual professional or an organization) or similar
                    institution wishing to be listed, or already listed, on the
                    Website, including designated, authorized associates of such
                    practitioners or institutions{" "}
                    <b>("Practitioner(s)", "you" or "User");</b> or
                  </p>
                  <ul>
                    <li>
                      A patient, his/her representatives or affiliates,
                      searching for Practitioners through the Website
                      <b>("End-User", "you" or "User");</b> or
                    </li>
                    <li>
                      Otherwise a user of the Website <b>("you" or "User")</b>.
                      <br />
                      This Agreement applies to those services made available by
                      Data-Fusion on the Website, which are offered free of
                      charge to the Users <b>("Services")</b>, including the
                      following:
                    </li>
                    <li>
                      For Practitioners: Listing of Practitioners and their
                      profiles and contact details, to be made available to the
                      other Users and visitors to the Website;
                    </li>
                    <li>
                      For other Users: Facility to (i) create and maintain
                      'Health Accounts', (ii) search for Practitioners by name,
                      specialty, and geographical area, or any other criteria
                      that may be developed and made available by EMRLink
                      Gateway, and (iii) to make appointments with
                      Practitioners.
                      <br />
                      The Services may change from time to time, at the sole
                      discretion of Data-Fusion, and the Agreement will apply to
                      your visit to and your use of the Website to avail the
                      Service, as well as to all information provided by you on
                      the Website at any given point in time.
                      <br />
                      This Agreement defines the terms and conditions under
                      which you are allowed to use the Website and describes the
                      manner in which we shall treat your account while you are
                      registered as a member with us. If you have any questions
                      about any part of the Agreement, feel free to contact us
                      at support@Data-Fusion.com.
                      <br />
                      By downloading or accessing the Website to use the
                      Services, you irrevocably accept all the conditions
                      stipulated in this Agreement, the Subscription Terms &
                      Conditions and Privacy Policy, as available on the
                      Website, and agree to abide by them. This Agreement
                      supersedes all previous oral and written terms and
                      conditions (if any) communicated to you relating to your
                      use of the Website to avail the Services. By availing any
                      Service, you signify your acceptance of the terms of this
                      Agreement. We reserve the right to modify or terminate any
                      portion of the Agreement for any reason and at any time,
                      and such modifications shall be informed to you in writing
                      You should read the Agreement at regular intervals. Your
                      use of the Website following any such modification
                      constitutes your agreement to follow and be bound by the
                      Agreement so modified.
                      <br />
                      You acknowledge that you will be bound by this Agreement
                      for availing any of the Services offered by us. If you do
                      not agree with any part of the Agreement, please do not
                      use the Website or avail any Services. <br />
                      Your access to use of the Website and the Services will be
                      solely at the discretion of Data-Fusion. <br />
                      The Agreement is published in compliance of, and is
                      governed by the provisions of Indian law, including but
                      not limited to:
                    </li>
                    <li>the Indian Contract Act, 1872,</li>
                    <li>the (Indian) Information Technology Act, 2000, and</li>
                    <li>
                      the rules, regulations, guidelines and clarifications
                      framed there under, including the (Indian) Information
                      Technology (Reasonable Security Practices and Procedures
                      and Sensitive Personal Information) Rules, 2011 (the{" "}
                      <b>"SPI Rules"</b>), and the (Indian) Information
                      Technology (Intermediaries Guidelines) Rules, 2011 (the{" "}
                      <b>"IG Rules"</b>).
                    </li>
                  </ul>
                </ul>
                <br></br>
                <h4>2.CONDITIONS OF USE</h4>
                <p>
                  You must be 18 years of age or older to register, use the
                  Services, or visit or use the Website in any manner. By
                  registering, visiting and using the Website or accepting this
                  Agreement, you represent and warrant to Data-Fusion that you
                  are 18 years of age or older, and that you have the right,
                  authority and capacity to use the Website and the Services
                  available through the Website, and agree to and abide by this
                  Agreement.
                </p>
                <br></br>
                <h4>
                  3.TERMS OF USE APPLICABLE TO ALL USERS OTHER THAN
                  PRACTITIONERS
                </h4>
                <p>
                  The terms in this Clause 3 are applicable only to Users other
                  than Practitioners.
                </p>
                <ul>
                  <li>END-USER ACCOUNT AND DATA PRIVACY</li>
                  <ul>
                    <li>
                      The terms "personal information" and "sensitive personal
                      data or information" are defined under the SPI Rules, and
                      are reproduced in the Privacy Policy.
                    </li>
                    <li>
                      Data-Fusion may by its Services, collect information
                      relating to the devices through which you access the
                      Website, and anonymous data of your usage. The collected
                      information will be used only for improving the quality of
                      Data-Fusion's services and to build new services.
                    </li>
                    <li>
                      The Website allows Data-Fusion to have access to
                      registered Users' personal email or phone number, for
                      communication purpose so as to provide you a better way of
                      booking appointments and for obtaining feedback in
                      relation to the Practitioners and their practice.
                    </li>
                    <li>
                      The Privacy Policy sets out, inter-alia: <br /> The type
                      of information collected from Users, including sensitive
                      personal data or information;
                    </li>
                    <ul>
                      <li>
                        The purpose, means and modes of usage of such
                        information;
                      </li>
                      <li>
                        How and to whom Data-Fusion will disclose such
                        information; and,
                      </li>
                      <li>Other information mandated by the SPI Rules.</li>
                    </ul>
                    <li>
                      The User is expected to read and understand the Privacy
                      Policy, so as to ensure that he or she has the knowledge
                      of, inter-alia: <br /> . the fact that certain information
                      is being collected;
                    </li>
                    <ul>
                      <li>
                        the purpose for which the information is being
                        collected;
                      </li>
                      <li> the intended recipients of the information;</li>
                      <li>
                        the nature of collection and retention of the
                        information; and
                      </li>
                      <li>
                        the name and address of the agency that is collecting
                        the information and the agency that will retain the
                        information; and
                      </li>
                      <li>
                        the various rights available to such Users in respect of
                        such information.
                      </li>
                    </ul>
                    <li>
                      Data-Fusion shall not be responsible in any manner for the
                      authenticity of the personal information or sensitive
                      personal data or information supplied by the User to EMR
                      Connect or to any other person acting on behalf of EMR
                      Connect.
                    </li>
                    <li>
                      The User is responsible for maintaining the
                      confidentiality of the User's account access information
                      and password, if the User is registered on the Website.
                      The User shall be responsible for all usage of the User's
                      account and password, whether or not authorized by the
                      User. The User shall immediately notify Data-Fusion of any
                      actual or suspected unauthorized use of the User's account
                      or password. Although Data-Fusion will not be liable for
                      your losses caused by any unauthorized use of your
                      account, you may be liable for the losses of EMRLink
                      Gateway or such other parties as the case may be, due to
                      any unauthorized use of your account.
                    </li>
                    <li>
                      If a User provides any information that is untrue,
                      inaccurate, not current or incomplete (or becomes untrue,
                      inaccurate, not current or incomplete), or Data-Fusion has
                      reasonable grounds to suspect that such information is
                      untrue, inaccurate, not current or incomplete, EMRLink
                      Gateway has the right to discontinue the Services to the
                      User at its sole discretion.
                    </li>
                    <li>
                      Data-Fusion may use such information collected from the
                      Users from time to time for the purposes of debugging
                      customer support related issues.
                    </li>
                    <li>
                      Against every Practitioner listed in Data-Fusion.com, you
                      may see a 'show number' option. When you choose this
                      option, you choose to call the number through a free
                      telephony service provided by Data-Fusion, and the records
                      of such calls are recorded and stored in EMRLink Gateway's
                      servers. Such call will have an IVR message stating the
                      purpose of recording your calls and your consent to such
                      recordings which are dealt with as per the Privacy Policy.
                      Such records may be accessed by EMRLink Gateway for
                      quality control and support related purposes and are dealt
                      with only in accordance with the terms of the Privacy
                      Policy. Such call facility provided to you by Data-Fusion
                      should be used only for appointment and booking purposes,
                      and not for consultation on health-related issues.
                      Data-Fusion accepts no liability if the call facility is
                      not used in accordance with the foregoing. Data-Fusion may
                      also choose to not use this facility and show the
                      practitioner's direct number. In case you choose to not
                      provide your consent to recording your calls that may have
                      personal information required for appointment and booking
                      purposes, EMRLink Gateway reserves the right to not
                      provide the Services for which such personal information
                      is sought.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h5>RELEVANCE ALGORITHM</h5>
                    <p>
                      Data-Fusion's relevance algorithm for the Practitioners is
                      a fully automated system that lists the Practitioners,
                      their profile and information regarding their Practice on
                      its Website. These listings of Practitioners do not
                      represent any fixed objective ranking or endorsement by
                      Data-Fusion. Data-Fusion will not be liable for any change
                      in the relevance of the Practitioners on search results,
                      which may take place from time to time. The listing of
                      Practitioners will be based on automated computation of
                      the various factors including inputs made by the Users
                      including their comments and feedback. Such factors may
                      change from time to time, in order to improve the listing
                      algorithm. Data-Fusion in no event will be held
                      responsible for the accuracy and the relevancy of the
                      listing order of the Practitioners on the Website.
                    </p>
                  </li>
                  <li>LISTING CONTENT AND DISSEMINATING INFORMATION</li>
                  <ul>
                    <li>
                      Data-Fusion collects, directly or indirectly, and displays
                      on the Website, relevant information regarding the profile
                      and practice of the Practitioners listed on the Website,
                      such as their specialization, qualification, fees,
                      location, visiting hours, and similar details. Data-Fusion
                      takes reasonable efforts to ensure that such information
                      is updated at frequent intervals. Although Data-Fusion
                      screens and vets the information and photos submitted by
                      the Practitioners, it cannot be held liable for any
                      inaccuracies or incompleteness represented from it,
                      despite such reasonable efforts.
                    </li>
                    <li>
                      The Services provided by Data-Fusion or any of its
                      licensors or service providers are provided on an "as is"
                      and "as available' basis, and without any warranties or
                      conditions (express or implied, including the implied
                      warranties of merchantability, accuracy, fitness for a
                      particular purpose, title and non-infringement, arising by
                      statute or otherwise in law or from a course of dealing or
                      usage or trade). Data-Fusion does not provide or make any
                      representation, warranty or guarantee, express or implied
                      about the Website or the Services. Data-Fusion does not
                      guarantee the accuracy or completeness of any content or
                      information provided by Users on the Website. To the
                      fullest extent permitted by law, Data-Fusion disclaims all
                      liability arising out of the User's use or reliance upon
                      the Website, the Services, representations and warranties
                      made by other Users, the content or information provided
                      by the Users on the Website, or any opinion or suggestion
                      given or expressed by EMRLink Gateway or any User in
                      relation to any User or services provided by such User.
                    </li>
                    <li>
                      The Website may be linked to the website of third parties,
                      affiliates and business partners. Data-Fusion has no
                      control over, and not liable or responsible for content,
                      accuracy, validity, reliability, quality of such websites
                      or made available by/through our Website. Inclusion of any
                      link on the Website does not imply that Data-Fusion
                      endorses the linked site. User may use the links and these
                      services at User's own risk.
                    </li>
                    <li>
                      Data-Fusion assumes no responsibility, and shall not be
                      liable for, any damages to, or viruses that may infect
                      User's equipment on account of User's access to, use of,
                      or browsing the Website or the downloading of any
                      material, data, text, images, video content, or audio
                      content from the Website. If a User is dissatisfied with
                      the Website, User's sole remedy is to discontinue using
                      the Website.
                    </li>
                    <li>
                      If Data-Fusion determines that you have provided
                      fraudulent, inaccurate, or incomplete information,
                      including through feedback, Data-Fusion reserves the right
                      to immediately suspend your access to the Website or any
                      of your accounts with Data-Fusion and makes such
                      declaration on the website alongside your name/your
                      clinic's name as determined by Data-Fusion for the
                      protection of its business and in the interests of Users.
                      You shall be liable to indemnify Data-Fusion for any
                      losses incurred as a result of your misrepresentations or
                      fraudulent feedback that has adversely affected EMR
                      Connect or its Users.
                    </li>
                    <li>
                      The information published under the head of "Industry wide
                      city-wise Pricing Graph" is derived from a benchmarking
                      group that is determined to be relevant by Data-Fusion in
                      the relevant city. Data-Fusion has published this
                      information for guidance purpose and does not have the
                      ability to ascertain accuracy of the data based on which
                      the information has been published and nor does it have
                      any duty to disclose to anyone the source of the
                      underlying data sets based on which this information has
                      been published. Data-Fusion is not liable in any manner
                      for any consequence whatsoever arising out of any use of
                      the information published here.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h5>BOOK APPOINTMENT AND CALL FACILITY</h5>
                    <p>
                      Data-Fusion enables Users to connect with Practitioners
                      through two methods: <br /> a) Book facility that allows
                      Users book an appointment through the Website; <br /> b)
                      Value added telephonic services which connect Users
                      directly to the Practitioner's number provided on the
                      Website.
                    </p>
                  </li>
                  <ul>
                    <li>
                      Data-Fusion will ensure Users are provided confirmed
                      appointment on the Book facility. However, Data-Fusion has
                      no liability if such an appointment is later cancelled by
                      the Practitioner, or the same Practitioner is not
                      available for appointment. Provided, it does not fall
                      under the heads listed under the{" "}
                      <a
                        href="https://www.practo.com/company/prime-benefits-users"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Data-Fusion Guarantee Program
                      </a>
                      , in which case the terms of this program shall apply.
                    </li>
                    <li>
                      If a User has utilized the telephonic services, EMR
                      Connect reserves the right to share the information
                      provided by the User with the Practitioner and store such
                      information and/or conversation of the User with the
                      Practitioner, in accordance with our Privacy Policy
                    </li>
                    <li>
                      The results of any search Users perform on the Website for
                      Practitioners should not be construed as an endorsement by
                      Data-Fusion of any such particular Practitioner. If the
                      User decides to engage with a Practitioner to seek medical
                      services, the User shall be doing so at his/her own risk.
                    </li>
                    <li>
                      Without prejudice to the generality of the above, EMR
                      Connect is not involved in providing any healthcare or
                      medical advice or diagnosis and hence is not responsible
                      for any interactions between User and the Practitioner.
                      User understands and agrees that Data-Fusion will not be
                      liable for: <br />
                      User interactions and associated issues User has with the
                      Practitioner;
                    </li>
                    <ul>
                      <li>
                        {" "}
                        the ability or intent of the Practitioner(s) or the lack
                        of it, in fulfilling their obligations towards Users;
                      </li>
                      <li>
                        any wrong medication or quality of treatment being given
                        by the Practitioner(s), or any medical negligence on
                        part of the Practitioner(s);
                      </li>
                      <li>
                        inappropriate treatment, or similar difficulties or any
                        type of inconvenience suffered by the User due to a
                        failure on the part of the Practitioner to provide
                        agreed Services;
                      </li>
                      <li>
                        any misconduct or inappropriate behaviour by the
                        Practitioner or the Practitioner's staff;
                      </li>
                    </ul>
                    <li>
                      Users are allowed to provide feedback about their
                      experiences with the Practitioner and/or schools, however,
                      the User shall ensure that, the same is provided in
                      accordance with applicable law. User however understands
                      that, Data-Fusion shall not be obliged to act in such
                      manner as may be required to give effect to the content of
                      Users feedback, such as suggestions for delisting of a
                      particular Practitioner from the Website.
                      <br />
                      Following instances, solely at the discretion of EMR
                      Connect, would be construed as valid cases of PNS ("Valid
                      PNS"), in which case the User shall be penalized as per
                      Clause 3.4.6 (a):
                      <br />
                      User does not reply within seven (7) days, with reasons to
                      PNS Communication, from the date of receipt of such PNS
                      Communication;
                    </li>
                    <ul>
                      <li>
                        Data-Fusion reserves the right to make the final
                        decision in case of a conflict. The total aggregate
                        liability of Data-Fusion with respect to any claims made
                        herein shall be INR 250.
                      </li>
                    </ul>
                  </ul>
                  <br></br>
                  <li>
                    <h5>
                      NO DOCTOR-PATIENT RELATIONSHIP; NOT FOR EMERGENCY USE
                    </h5>
                  </li>
                  <ul>
                    <li>
                      Please note that some of the content, text, data,
                      graphics, images, information, suggestions, guidance, and
                      other material (collectively, "Information") that may be
                      available on the Website (including information provided
                      in direct response to your questions or postings) may be
                      provided by individuals in the medical profession. The
                      provision of such Information does not create a licensed
                      medical professional/patient relationship, between EMR
                      Connect and you and does not constitute an opinion,
                      medical advice, or diagnosis or treatment of any
                      particular condition, but is only provided to assist you
                      with locating appropriate medical care from a qualified
                      practitioner.
                    </li>
                    <li>
                      It is hereby expressly clarified that, the Information
                      that you obtain or receive from Data-Fusion, and its
                      employees, contractors, partners, sponsors, advertisers,
                      licensors or otherwise on the Website is for informational
                      purposes only. We make no guarantees, representations or
                      warranties, whether expressed or implied, with respect to
                      professional qualifications, quality of work, expertise or
                      other information provided on the Website. In no event
                      shall we be liable to you or anyone else for any decision
                      made or action taken by you in reliance on such
                      information.
                    </li>
                    <li>
                      The Services are not intended to be a substitute for
                      getting in touch with emergency healthcare. If you are an
                      End-User facing a medical emergency (either on your or a
                      another person's behalf), please contact an ambulance
                      service or hospital directly.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h5>Data-Fusion HEALTH FEED</h5>
                    <p>
                      These terms & conditions governing Data-Fusion Health feed
                      are applicable to Users (being both end-users/
                      Practitioner). However, it is clarified that the terms and
                      conditions herein applicable only to Practitioners and
                      applicable to Users are called out separately, as the
                      context warrants.
                    </p>
                  </li>
                  <ul>
                    <li>
                      Data-Fusion Health feed is an online content platform
                      available on the Website, wherein Practitioners who have
                      created a Data-Fusion profile can login and post health
                      and wellness related content.
                    </li>
                    <li>
                      Practitioners can use Data-Fusion Health feed by logging
                      in from their health account, creating original content
                      comprising text, audio, video, images, data or any
                      combination of the same <b>("Content")</b>, and uploading
                      said Content to Data-Fusion's servers. Data-Fusion will
                      make available to the User a gallery of images licensed by
                      Data-Fusion from a third party stock image provider
                      <b>("Data-Fusion Gallery")</b>. The User can upload their
                      own images or choose an image from the EMRLink Gateway
                      Gallery. Data-Fusion does not provide any warranty as to
                      the ownership of the intellectual property in the EMR
                      Connect Gallery and the User acknowledges that the User
                      will use the images from the Data-Fusion Gallery at their
                      own risk. Data-Fusion shall post such Content to EMR
                      Connect Health feed at its own option and subject to these
                      Terms and Conditions. The Content uploaded via Data-Fusion
                      Health feed does not constitute medical advice and may not
                      be construed as such by any person.
                    </li>
                    <li>
                      Practitioners acknowledges that they are the original
                      authors and creators of any Content uploaded by them via
                      Data-Fusion Health feed and that no Content uploaded by
                      them would constitute infringement of the intellectual
                      property rights of any other person. Data-Fusion reserves
                      the right to remove any Content which it may determine at
                      its own discretion as violating the intellectual property
                      rights of any other person, including but not limited to
                      patent, trademark, copyright or other proprietary rights.
                      Practitioner agrees to absolve Data-Fusion from and
                      indemnify Data-Fusion against all claims that may arise as
                      a result of any third party intellectual property right
                      claim that may arise from the Practitioner's uploading of
                      any Content on the Data-Fusion Health feed. The
                      Practitioner may not use the images in the Data-Fusion
                      Gallery for any purpose other than those directly related
                      to the creation and uploading of Content to Data-Fusion
                      Health feed. The Practitioner also agrees to absolve EMR
                      Connect from and indemnify Data-Fusion against all claims
                      that may arise as a result of any third party intellectual
                      property claim if the Practitioner downloads, copies or
                      otherwise utilizes an image from the Data-Fusion Gallery
                      for his/her personal or commercial gain.
                    </li>
                    <li>
                      Practitioner hereby assigns to Data-Fusion, in perpetuity
                      and worldwide, all intellectual property rights in any
                      Content created by the User and uploaded by the User via
                      Data-Fusion Health feed.
                    </li>
                    <li>
                      Data-Fusion shall have the right to edit or remove the
                      Content and any comments in such manner as it may deem EMR
                      Connect Health feed at any time.
                    </li>
                    <li>
                      Practitioner shall ensure that the Content or any further
                      responses to the Content (including responses to Users) is
                      not harmful, harassing, blasphemous, defamatory, obscene,
                      pornographic, paedophilic or libelous in any manner.
                      Further, Practitioner should ensure that the Content is
                      not invasive of any other person's privacy, or otherwise
                      contains any elements that is hateful, racially or
                      ethnically objectionable, disparaging, or otherwise
                      unlawful in any manner whatever. Data-Fusion reserves the
                      right to remove any Content which it may determine at its
                      own discretion is violative of these Terms and Conditions
                      or any law or statute in force at the time. Also, the
                      Practitioner agrees to absolve Data-Fusion from and
                      indemnify Data-Fusion against all claims that may arise as
                      a result of any legal claim arising from the nature of the
                      Content posted by the Practitioner on Data-Fusion Health
                      Feed.
                    </li>
                    <li>
                      Practitioner shall ensure that no portion of the Content
                      is violative of any law for the time being in force.
                    </li>
                    <li>
                      Practitioner shall ensure that the Content is not
                      threatening the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order. Further the Practitioner shall
                      ensure that the Content will not cause incitement to the
                      commission of any cognisable offence or prevent
                      investigation of any offence or is insulting to any other
                      nation.
                    </li>
                    <li>
                      User may also use Data-Fusion Health feed in order to view
                      original content created by Practitioners and to create
                      and upload comments on such Content, where allowed{" "}
                      <b>("User Comment")</b>.
                    </li>
                    <li>
                      User acknowledges that the User Comment reflects the views
                      and opinions of the authors of such Content and do not
                      necessarily reflect the views of Data-Fusion.
                    </li>
                    <li>
                      User agrees that the Content they access on EMRLink
                      Gateway Health feed does not in any way constitute medical
                      advice and that the responsibility for any act or omission
                      by the User arising from the User's interpretation of the
                      Content, is solely attributable to the User. The User
                      agrees to absolve Data-Fusion from and indemnify EMR
                      Connect against all claims that may arise as a result of
                      the User's actions resulting from the User's viewing of
                      Content on Data-Fusion Health feed.
                    </li>
                    <li>
                      User acknowledges that all intellectual property rights in
                      the User Comment on Data-Fusion Health feed vests with EMR
                      Connect. The User agrees not to infringe upon EMR
                      Connect's intellectual property by copying or plagiarizing
                      content on Data-Fusion Health feed. Data-Fusion reserves
                      its right to initiate all necessary legal remedies
                      available to them in case of such an infringement by the
                      User. Also, User Comment will be the sole intellectual
                      property of Data-Fusion. The User agrees not to post User
                      Comment that would violate the intellectual property of
                      any third party, including but not limited to patent,
                      trademark, copyright or other proprietary rights. EMR
                      Connect reserves the right to remove any User Comment
                      which it may determine at its own discretion as violating
                      the intellectual property rights of any third party. The
                      User agrees to absolve EMRLink Gateway from and indemnify
                      EMR Connect against all claims that may arise as a result
                      of any third party intellectual property right claim that
                      may arise from the User Comment.
                    </li>
                    <li>
                      User shall ensure that the User Comment is not harmful,
                      harassing, blasphemous, defamatory, obscene, pornographic,
                      paedophilic or libelous in any manner. Further, User
                      should ensure that the User Comment is not invasive of any
                      other person's privacy, or otherwise contains any elements
                      that is hateful, racially or ethnically objectionable,
                      disparaging, or otherwise unlawful in any manner whatever.
                      Data-Fusion reserves the right to remove any Content which
                      it may determine at its own discretion is violative of
                      these Terms and Conditions or any law or statute in force
                      at the time Also, the User agrees to absolve EMRLink
                      Gateway from and indemnify Data-Fusion against all claims
                      that may arise as a result of any legal claim arising from
                      the nature of the User Comment.
                    </li>
                    <li>
                      User shall ensure that the User Comment is not threatening
                      the unity, integrity, defence, security or sovereignty of
                      India, friendly relations with foreign states, or public
                      order. Further the Practitioner shall ensure that the User
                      Comment will not cause incitement to the commission of any
                      cognisable offence or prevent investigation of any offence
                      or is insulting to any other nation.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h5>
                      CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS
                    </h5>
                  </li>

                  <ul>
                    <li>
                      The contents listed on the Website are <br /> (i) User
                      generated content, or <br />
                      (ii) belong to Data-Fusion. The information that is
                      collected by Data-Fusion directly or indirectly from the
                      End- Users and the Practitioners shall belong to EMR
                      Connect. Copying of the copyrighted content published by
                      Data-Fusion on the Website for any commercial purpose or
                      for the purpose of earning profit will be a violation of
                      copyright and Data-Fusion reserves its rights under
                      applicable law accordingly.
                    </li>
                    <li>
                      Data-Fusion authorizes the User to view and access the
                      content available on or from the Website solely for
                      ordering, receiving, delivering and communicating only as
                      per this Agreement. The contents of the Website,
                      information, text, graphics, images, logos, button icons,
                      software code, design, and the collection, arrangement and
                      assembly of content on the Website (collectively,
                      <b>"Data-Fusion Content"</b>), are the property of EMR
                      Connect and are protected under copyright, trademark and
                      other laws. User shall not modify the Data-Fusion Content
                      or reproduce, display, publicly perform, distribute, or
                      otherwise use the Data-Fusion Content in any way for any
                      public or commercial purpose or for personal gain.
                    </li>
                    <li>
                      User shall not access the Services for purposes of
                      monitoring their availability, performance or
                      functionality, or for any other benchmarking or
                      competitive purposes.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h5>REVIEWS AND FEEDBACK</h5>
                    <p>
                      By using this Website, you agree that any information
                      shared by you with Data-Fusion or with any Practitioner
                      will be subject to our Privacy Policy. <br /> You are
                      solely responsible for the content that you choose to
                      submit for publication on the Website, including any
                      feedback, ratings, or reviews ("Critical Content")
                      relating to Practitioners or other healthcare
                      professionals. The role of Data-Fusion in publishing
                      Critical Content is restricted to that of an
                      'intermediary' under the Information Technology Act, 2000.
                      Data-Fusion disclaims all responsibility with respect to
                      the content of Critical Content, and its role with respect
                      to such content is restricted to its obligations as an
                      'intermediary' under the said Act. Data-Fusion shall not
                      be liable to pay any consideration to any User for
                      re-publishing any content across any of its platforms.{" "}
                      <br /> Your publication of reviews and feedback on the
                      Website is governed by Clause 5 of these Terms. Without
                      prejudice to the detailed terms stated in Clause 5, you
                      hereby agree not to post or publish any content on the
                      Website that (a) infringes any third-party intellectual
                      property or publicity or privacy rights, or (b) violates
                      any applicable law or regulation, including but not
                      limited to the IG Rules and SPI Rules. Data-Fusion, at its
                      sole discretion, may choose not to publish your reviews
                      and feedback, if so required by applicable law, and in
                      accordance with Clause 5 of these Terms. You agree that
                      Data-Fusion may contact you through telephone, email, SMS,
                      or any other electronic means of communication for the
                      purpose of:
                      <br />
                    </p>
                  </li>
                  <ul>
                    <li>
                      . Obtaining feedback in relation to Website or EMR
                      Connect's services; and/or
                    </li>
                    <li>
                      Obtaining feedback in relation to any Practitioners listed
                      on the Website; and/or
                    </li>
                    <li>
                      Resolving any complaints, information, or queries by
                      Practitioners regarding your Critical Content;
                      <br />
                      and you agree to provide your fullest co-operation further
                      to such communication by Data-Fusion. EMRLink Gateway's
                      Feedback Collection and Fraud Detection Policy, is annexed
                      as the Schedule hereto, and remains subject always to
                      these Terms.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h5>RECORDS</h5>
                    <p>
                      Data-Fusion may provide End-Users with a free facility
                      known as 'Records' on its mobile application 'EMR
                      Connect'. Information available in your Records is of two
                      types:
                    </p>
                  </li>
                  <ul>
                    <li>
                      User-created: Information uploaded by you or information
                      generated during your interaction with Data-Fusion
                      ecosystem, eg: appointment, medicine order placed by you.
                    </li>
                    <li>
                      Practice-created: Health Records generated by your
                      interaction with a Practitioner who uses 'Data-Fusion Ray'
                      or other Services of Data-Fusion software.
                    </li>
                    <p>
                      The specific terms relating to such Health Account are as
                      below, without prejudice to the rest of these Terms and
                      the Privacy Policy:
                    </p>
                  </ul>
                  <ul>
                    <li>
                      Your Records is only created after you have signed up and
                      explicitly accepted these Terms.
                    </li>
                    <li>
                      Any Practice created Health Record is provided on an as-is
                      basis at the sole intent, risk and responsibility of the
                      Practitioner and Data-Fusion does not validate the said
                      information and makes no representation in connection
                      therewith. You should contact the relevant Practitioner in
                      case you wish to point out any discrepancies or add,
                      delete, or modify the Health Record in any manner.
                    </li>
                    <li>
                      The Health Records are provided on an as-is basis. While
                      we strive to maintain the highest levels of service
                      availability, Data-Fusion is not liable for any
                      interruption that may be caused to your access of the
                      Services.
                    </li>
                    <li>
                      The reminder provided by the Records is only a
                      supplementary way of reminding you to perform your
                      activities as prescribed by your Practitioner. In the
                      event of any medicine reminders provided by EMRLink
                      Gateway, you should refer to your prescription before
                      taking any medicines. Data-Fusion is not liable if for any
                      reason reminders are not delivered to you or are delivered
                      late or delivered incorrectly, despite its best efforts.
                      In case you do not wish to receive the reminders, you can
                      switch it off through the Data-Fusion app.
                    </li>
                    <li>
                      It is your responsibility to keep your correct mobile
                      number and email ID updated in the Records. The Health
                      Records will be sent to the Records associated with this
                      mobile number and/or email ID. Every time you change any
                      contact information (mobile or email), we will send a
                      confirmation. Data-Fusion is not responsible for any loss
                      or inconvenience caused due to your failure in updating
                      the contact details with Data-Fusion.
                    </li>
                    <li>
                      Data-Fusion uses industry–level security and encryption to
                      your Health Records. However, EMRLink Gateway does not
                      guarantee to prevent unauthorized access if you lose your
                      login credentials or they are otherwise compromised. In
                      the event you are aware of any unauthorized use or access,
                      you shall immediately inform Data-Fusion of such
                      unauthorized use or access. Please safeguard your login
                      credentials and report any actual suspected breach of
                      account to support@EMRLink Gateway.com.
                    </li>
                    <li>
                      If you access your dependents' Health Records by
                      registering your dependents with your own Records, you are
                      deemed to be responsible for the Health Records of your
                      dependents and all obligations that your dependents would
                      have had, had they maintained their own separate
                      individual Records. You agree that it shall be your sole
                      responsibility to obtain prior consent of your dependent
                      and shall have right to share, upload and publish any
                      sensitive personal information of your dependent. EMR
                      Connect assumes no responsibility for any claim, dispute
                      or liability arising in this regard, and you shall
                      indemnify Data-Fusion and its officers against any such
                      claim or liability arising out of unauthorized use of such
                      information.
                    </li>
                    <li>
                      In case you want to delete your Records, you can do so by
                      contacting our service support team. However only your
                      account and any associated Health Records will be deleted,
                      and your Health Records stored by your Practitioners will
                      continue to be stored in their respective accounts.
                    </li>
                    <li>
                      You may lose your "User created" record, if the data is
                      not synced with the server.
                    </li>
                    <li>
                      If the Health Record is unassessed for a stipulated time,
                      you may not be able to access your Health Records due to
                      security reasons.
                    </li>
                    <li>
                      Data-Fusion is not liable if for any reason, Health
                      Records are not delivered to you or are delivered late
                      despite its best efforts.
                    </li>
                    <li>
                      The Health Records are shared with the phone numbers that
                      are provided by your Practitioner. Data-Fusion is not
                      responsible for adding the Heath Records with incorrect
                      numbers if those incorrect numbers are provided by the
                      Practitioner.
                    </li>
                    <li>
                      Data-Fusion is not responsible or liable for any content,
                      fact, Health Records, medical deduction or the language
                      used in your Health Records whatsoever. Your Practitioner
                      is solely responsible and liable for your Health Records
                      and any information provided to us including but not
                      limited to the content in them.
                    </li>
                    <li>
                      Data-Fusion has the ability in its sole discretion to
                      retract Health Records without any prior notice if they
                      are found to be shared incorrectly or inadvertently.
                    </li>
                    <li>
                      Data-Fusion will follow the law of land in case of any
                      constitutional court or jurisdiction mandates to share the
                      Health Records for any reason.
                    </li>
                    <li>
                      You agree and acknowledge that Data-Fusion may need to
                      access the Health Record for cases such as any technical
                      or operational issue of the End User in access or
                      ownership of the Records.
                    </li>
                    <li>
                      You acknowledge that the Practitioners you are visiting
                      may engage Data-Fusion's software or third party software
                      for the purposes of the functioning of the Practitioner's
                      business and Data-Fusion's services including but not
                      limited to the usage and for storage of Records (as
                      defined in Section 3.10) in India and outside India, in
                      accordance with the applicable laws.
                    </li>
                    <li>
                      To the extent that your Records have been shared with EMR
                      Connect or stored on any of the Data-Fusion products used
                      by Practitioner's you are visiting, and may in the past
                      have visited, You hereby agree to the storage of your
                      Records by Data-Fusion pertaining to such previously
                      visited clinics and hospitals who have tie ups with EMR
                      Connect for the purposes of their business and for EMR
                      Connect's services including but not limited to the usage
                      and for storage of Records (as defined in Section 3.10) in
                      India and outside India, in accordance with the applicable
                      laws and further agree, upon creation of your account with
                      Data-Fusion, to the mapping of such Records as may be
                      available in Data-Fusion's database to your User account.
                    </li>
                  </ul>
                  <li>
                    Data-Fusion MEDICINE INFORMATION
                    <br />
                    For detailed terms and conditions regarding medicine
                    information click{" "}
                    <a
                      rel="noreferrer"
                      href="https://www.practo.com/medicine-info/terms"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </li>
                  <li>
                    <b>Data-Fusion Q&A</b>
                  </li>
                  <ul>
                    <li>Terms for Practitioners:</li>
                    <ul>
                      <li>
                        Every Practitioner on the Q&A Platform must be qualified
                        in the area of expertise that he represents as being his
                        qualification.
                      </li>
                      <li>
                        The Q&A Platform is a platform for exchange of
                        information which is of general nature in a question and
                        answer format.{" "}
                        <b>The Q&A Platform is not for emergency situations.</b>
                      </li>
                      <li>
                        {" "}
                        Any Q&A interaction cannot be construed as a medical
                        consultation with the Practitioner, in any manner
                        whatsoever.
                      </li>
                      <li>
                        Data-Fusion Users may post questions on medical issues
                        <b>("Querist")</b>, and these queries can be answered by
                        multiple Practitioners on the Q&A Platform. It is
                        expressly clarified that no doctor-patient relationship
                        is established between the Querist and Practitioner, in
                        any manner whatsoever, by indulging in this Q&A.
                      </li>
                      <li>
                        The Practitioner may provide <b>probable</b> views,
                        recommendations, suggestions and solutions to the
                        question posted by a Querist. However, it is expressly
                        clarified that any such view, recommendation, suggestion
                        and solution shall not be construed as medical advice.
                        The person in question (being Querist or not) is advised
                        to consult with a doctor in this regard.
                      </li>
                      <li>
                        Practitioner shall not share their personal, religious
                        and/or moral views with the Querist while issuing their
                        response.
                      </li>
                      <li>
                        The Practitioner cannot issue and/or prescribe any
                        medicines on the Q&A platform. It is a platform for only
                        exchange of information and not a consultation/ medical
                        advice.
                      </li>
                      <li>
                        The Practitioner is{" "}
                        <b>absolutely and expressly prohibited</b>
                        to:
                      </li>
                      <ul>
                        <p>. Provide a medical diagnosis;</p>
                        <li>Issue a prescription;</li>
                        <li>
                          Post any response which comprises of any derogatory
                          language, objectionable, pornographic and /or
                          offensive content;
                        </li>
                        <li>
                          {" "}
                          Advertise any brand of drugs, supplements, vaccines
                          and medications, in any manner of whatsoever;
                        </li>
                        <li>
                          {" "}
                          Advertise to/solicit patients in any manner including
                          without limitation by way of using social media
                          handles, including without limitation, facebook,
                          twitter etc., telephone/ mobile numbers, youtube, and
                          address;
                        </li>
                        <li>
                          Promote content and activities which are illegal in
                          nature;
                        </li>
                      </ul>
                      <li>
                        {" "}
                        In the event of any breach of these QA T&C, EMRLink
                        Gateway will take appropriate action inter alia ranging
                        from issuing warnings to removal of Practitioner from
                        the Q&A Platform, in its sole and absolute discretion,
                        on a case to case basis.
                      </li>
                      <li>
                        Any liability arising out of the response issued by a
                        Practitioner will be borne solely by the Practitioner.
                        Q&A Platform is only a platform interface provided by
                        Data-Fusion for sharing and exchanging information
                        purposes.
                      </li>
                      <li>
                        The Q&A Platform shall be audited on a regular basis by
                        Data-Fusion for ascertaining compliance by the
                        Practitioner to Paragraph 6, and the Practitioners
                        consent to the same. It is clarified that the said audit
                        is not conducted for the purpose of quality check or
                        validation of responses issued by Practitioner.
                      </li>
                      <li>
                        All general Data-Fusion Terms and Conditions shall
                        govern the QA T&C to the extent applicable. Decision of
                        Data-Fusion is final and binding on the issues arising
                        under the QA T&C and Data-Fusion Terms and Conditions.
                      </li>
                    </ul>
                    <br></br>
                    <li>
                      <h5>Terms for User/Querist:</h5>
                    </li>
                    <ul>
                      <li>
                        {" "}
                        The personally identifiable information of the querist
                        shall be anonymized by Data-Fusion prior to posting the
                        question on the Q&A Platform;
                      </li>
                      <li>
                        Q&A Platform is a public forum for exchange of
                        information, hence, any question posted by a querist is
                        visible publicly. It is advisable for a User to ensure
                        that no confidential information is published by them on
                        the Q&A Platform.
                      </li>
                      <li>
                        {" "}
                        User shall not use the Q&A Platform for emergency
                        situations.
                      </li>
                      <li>
                        The User is prohibited to post any query/issue on the
                        Q&A Platform, which comprises of any derogatory
                        language, objectionable, pornographic and /or offensive
                        content;
                      </li>
                      <li>
                        Any response provided by the Practitioner shall not be
                        construed as medical advice on the Q&A Platform. Q&A
                        Platform is a platform for exchange of information which
                        is of general nature in a question and answer format.
                        This cannot be construed as a medical consultation with
                        the Practitioner, in any manner whatsoever.
                      </li>
                      <li>
                        {" "}
                        It is expressly clarified that no doctor-patient
                        relationship is established between the user and
                        Practitioner, in any manner whatsoever, by indulging in
                        this Q&A. The Practitioner may provide <b>
                          probable
                        </b>{" "}
                        views, recommendations, suggestions and solutions to the
                        question posted. However, it is expressly clarified that
                        any such view, recommendation, suggestion and solution
                        shall not be construed as medical advice. The person in
                        question (being Querist or not) is advised to consult
                        with a doctor in this regard.
                      </li>
                      <li>
                        All general Data-Fusion Terms and Conditions shall
                        govern the QA T&C to the extent applicable. Decision of
                        Data-Fusion is final and binding on the issues arising
                        under the QA T&C and Data-Fusion Terms and Conditions.
                      </li>
                    </ul>
                  </ul>
                  <br></br>
                  <li>
                    <h5>Data-Fusion DIAGNOSTICS</h5>
                    <p>
                      For detailed terms and conditions regarding diagnostics
                      click{" "}
                      <a
                        href="https://www.practo.com/company/terms/diagnostic"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                      .
                    </p>
                  </li>
                  <br></br>
                  <li>
                    <h5>Data-Fusion HEALTHCASH</h5>
                  </li>
                  <ul>
                    <li>
                      Data-Fusion HealthCash is a grant of benefit in the form
                      of credits in the Data-Fusion accounts of the Users
                      <b>("Data-Fusion HealthCash")</b> in the form and under
                      such circumstances decided by Data-Fusion from time to
                      time.
                    </li>
                    <li>
                      Data-Fusion HealthCash can be redeemed by the User only to
                      pay online for the following services on the Website:
                    </li>
                    <ul>
                      <li>Ordering medicines online;</li>
                      <li> Consulting a doctor online;</li>
                      <li>Booking diagnostic tests and health check-ups;</li>
                      <li>Pre-paying for doctor appointment bookings; and</li>
                      <li> Subscribing to Healthcare plans.</li>
                      <p>
                        {" "}
                        (The above services on the Website are collectively
                        referred to as "<b>Permitted Services</b>".)
                      </p>
                    </ul>
                    <li>
                      Every time a User using (i) Android version 4.31 or above;
                      or (ii) iOS version 4.24 or above of the Data-Fusion
                      mobile application; or (iii) the Data-Fusion website
                      (www.Data-Fusion.com), uses HealthCash to pay online for
                      any of the Permitted Services availed by him/her, only
                      such percentage of the total amount to be paid for the
                      availed Permitted Services, as may be prescribed by EMR
                      Connect from time to time on the respective EMRLink
                      Gateway mobile application or website ( www.EMRLink
                      Gateway.in ), can be redeemed by such User using the
                      Data-Fusion HealthCash per transaction.
                    </li>
                    <li>
                      Data-Fusion HealthCash neither can be converted to actual
                      money nor can be transferred to any bank accounts.
                    </li>
                    <li>
                      The Data-Fusion HealthCash received by each User may or
                      may not have an expiry date associated with it and the
                      said expiry date is subject to the following:
                    </li>
                    <ul>
                      <li>
                        The expiry date associated with Data-Fusion HealthCash
                        may vary from time to time. However, the same cannot
                        exceed beyond 2 (Two) years
                      </li>
                      <li>
                        The expiry date of Data-Fusion HealthCash may be
                        intimated to each User by way of SMS or e-mail or in-App
                        notification on the Website.
                      </li>
                      <li>
                        {" "}
                        The expiry date of Data-Fusion HealthCash can be viewed
                        by each User in the designated page for HealthCash in
                        the Website.
                      </li>
                      <li>
                        The User acknowledges that Data-Fusion has agreed to
                        offer Data-Fusion HealthCash in its sole discretion.
                        Nothing in these Terms shall be interpreted to restrict
                        Data-Fusion's right to withdraw the Data-Fusion
                        HealthCash before the expiry date.
                      </li>
                    </ul>
                    <li>
                      Data-Fusion reserves its discretion to deny EMRLink
                      Gateway HealthCash to any User in its sole discretion,
                      notwithstanding compliance by such User of the
                      requirements set out in the Terms, applicable law or
                      otherwise.
                    </li>
                    <li>
                      Other than the conditions set out in these Terms, accrual,
                      utilization, expiry or any other treatment of EMRLink
                      Gateway HealthCash may be further restricted by the terms
                      governing the specific service or facility offered by EMR
                      Connect or its business associates, the procurement of
                      which entitles Data-Fusion HealthCash to a User ("
                      <b>HealthCash Terms</b>"). In the event of any
                      inconsistency between these Terms and the HealthCash Terms
                      in relation to accrual, utilization, expiry or any other
                      treatment of Data-Fusion HealthCash by a User, the
                      HealthCash Terms shall override these Terms to the extent
                      of such inconsistency.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>TERMS OF USE PRACTITIONERS</h4>
                    <p>
                      The terms in this Clause 4 are applicable only to
                      Practitioners.
                    </p>
                  </li>
                  <ul>
                    <li>LISTING POLICY</li>
                    <ul>
                      <li>
                        Data-Fusion, directly and indirectly, collects
                        information regarding the Practitioners' profiles,
                        contact details, and practice. Data-Fusion reserves the
                        right to take down any Practitioner's profile as well as
                        the right to display the profile of the Practitioners,
                        with or without notice to the concerned Practitioner.
                        This information is collected for the purpose of
                        facilitating interaction with the End-Users and other
                        Users. If any information displayed on the Website in
                        connection with you and your profile is found to be
                        incorrect, you are required to inform EMRLink Gateway
                        immediately to enable Data-Fusion to make the necessary
                        amendments.
                      </li>
                      <li>
                        Data-Fusion shall not be liable and responsible for the
                        ranking of the Practitioners on external websites and
                        search engines
                      </li>
                      <li>
                        Data-Fusion shall not be responsible or liable in any
                        manner to the Users for any losses, damage, injuries or
                        expenses incurred by the Users as a result of any
                        disclosures or publications made by Data-Fusion, where
                        the User has expressly or implicitly consented to the
                        making of disclosures or publications by EMRLink
                        Gateway. If the User had revoked such consent under the
                        terms of the Privacy Policy, then Data-Fusion shall not
                        be responsible or liable in any manner to the User for
                        any losses, damage, injuries or expenses incurred by the
                        User as a result of any disclosures made by EMRLink
                        Gateway prior to its actual receipt of such revocation.
                      </li>
                      <li>
                        Data-Fusion reserves the right to moderate the
                        suggestions made by the Practitioners through feedback
                        and the right to remove any abusive or inappropriate or
                        promotional content added on the Website. However, EMR
                        Connect shall not be liable if any inactive, inaccurate,
                        fraudulent, or non- existent profiles of Practitioners
                        are added to the Website.
                      </li>
                      <li>
                        Practitioners explicitly agree that Data-Fusion reserves
                        the right to publish the Content provided by
                        Practitioners to a third party including content
                        platforms.
                      </li>
                      <li>
                        When you are listed on Data-Fusion.com, End-Users may
                        see a 'show number' option. When End-Users choose this
                        option, they choose to call your number through a free
                        telephony service provided by Data-Fusion, and the
                        records of such calls are recorded and stored in EMR
                        Connect's servers. Such call will have an IVR message
                        stating the purpose of recording your calls and your
                        consent to such recordings which are dealt with as per
                        the Privacy Policy. Such records may be accessed by EMR
                        Connect for quality control and support related purposes
                        and are dealt with only in accordance with the terms of
                        the Privacy Policy. Such call facility provided to
                        End-Users and to you by Data-Fusion should be used only
                        for appointment and booking purposes, and not for
                        consultation on health-related issues. Data-Fusion
                        accepts no liability if the call facility is not used in
                        accordance with the foregoing. In case you choose to not
                        provide your consent to recording your calls that may
                        have personal information required for appointment and
                        booking purposes, Data-Fusion reserves the right to not
                        provide the Services for which such personal information
                        is sought.
                      </li>
                      <li>
                        You as a Practitioner hereby represent and warrant that
                        you will use the Services in accordance with applicable
                        law. Any contravention of applicable law as a result of
                        your use of these Services is your sole responsibility,
                        and Data-Fusion accepts no liability for the same.
                      </li>
                    </ul>
                    <br></br>
                    <li>
                      <h5>PROFILE OWNERSHIP AND EDITING RIGHTS</h5>
                      <p>
                        Data-Fusion ensures easy access to the Practitioners by
                        providing a tool to update your profile information. EMR
                        Connect reserves the right of ownership of all the
                        Practitioner's profile and photographs and to moderate
                        the changes or updates requested by Practitioners.
                        However, Data-Fusion takes the independent decision
                        whether to publish or reject the requests submitted for
                        the respective changes or updates. You hereby represent
                        and warrant that you are fully entitled under law to
                        upload all content uploaded by you as part of your
                        profile or otherwise while using Data-Fusion's services,
                        and that no such content breaches any third party
                        rights, including intellectual property rights. Upon
                        becoming aware of a breach of the foregoing
                        representation, Data-Fusion may modify or delete parts
                        of your profile information at its sole discretion with
                        or without notice to you.
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>
                        REVIEWS AND FEEDBACK DISPLAY RIGHTS OF Data-Fusion
                      </h5>
                    </li>
                    <ul>
                      <li>
                        All Critical Content is content created by the Users of
                        www.Data-Fusion.com <b>("Website")</b> and the clients
                        of Data-Fusion customers and Practitioners, including
                        the End-Users. As a platform, Data-Fusion does not take
                        responsibility for Critical Content and its role with
                        respect to Critical Content is restricted to that of an
                        'intermediary' under the Information Technology Act,
                        2000. The role of Data-Fusion and other legal rights and
                        obligations relating to the Critical Content are further
                        detailed in Clauses 3.9 and 5 of these Terms. EMR
                        Connect's Feedback Collection and Fraud Detection
                        Policy, is annexed as the Schedule hereto, and remains
                        subject always to these Terms.
                      </li>
                      <li>
                        Data-Fusion reserves the right to collect feedback and
                        Critical Content for all the Practitioners, Clinics and
                        Healthcare Providers listed on the Website.
                      </li>
                      <li>
                        Data-Fusion shall have no obligation to pre-screen,
                        review, flag, filter, modify, refuse or remove any or
                        all Critical Content from any Service, except as
                        required by applicable law.
                      </li>
                      <li>
                        You understand that by using the Services you may be
                        exposed to Critical Content or other content that you
                        may find offensive or objectionable. Data-Fusion shall
                        not be liable for any effect on Practitioner's business
                        due to Critical Content of a negative nature. In these
                        respects, you may use the Service at your own risk. EMR
                        Connect however, as an 'intermediary, takes steps as
                        required to comply with applicable law as regards the
                        publication of Critical Content. The legal rights and
                        obligations with respect to Critical Content and any
                        other information sought to be published by Users are
                        further detailed in Clauses 3.9 and 5 of these Terms.
                      </li>
                      <li>
                        Data-Fusion will take down information under standards
                        consistent with applicable law, and shall in no
                        circumstances be liable or responsible for Critical
                        Content, which has been created by the Users. The
                        principles set out in relation to third party content in
                        the terms of Service for the Website shall be applicable
                        mutatis mutandis in relation to Critical Content posted
                        on the Website.
                      </li>
                      <li>
                        Data-Fusion determines that you have provided inaccurate
                        information or enabled fraudulent feedback, Data-Fusion
                        reserves the right to immediately suspend any of your
                        accounts with Data-Fusion and makes such declaration on
                        the website alongside your name/your clinics name as
                        determined by Data-Fusion for the protection of its
                        business and in the interests of Users.
                      </li>
                    </ul>
                    <br></br>
                    <li>
                      <h5>RELEVANCE ALGORITHM</h5>
                      <p>
                        Data-Fusion has designed the relevance algorithm in the
                        best interest of the End-User and may adjust the
                        relevance algorithm from time to time to improve the
                        quality of the results given to the patients. It is a
                        pure merit driven, proprietary algorithm which cannot be
                        altered for specific Practitioners. Data-Fusion shall
                        not be liable for any effect on the Practitioner's
                        business interests due to the change in the Relevance
                        Algorithm.
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>INDEPENDENT SERVICES</h5>
                      <p>
                        Your use of each Service confers upon you only the
                        rights and obligations relating to such Service, and not
                        to any other service that may be provided by EMR
                        Connect.
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>Data-Fusion REACH RIGHTS</h5>
                      <p>
                        Data-Fusion reserves the rights to display sponsored ads
                        on the Website. These ads would be marked as "Sponsored
                        ads". Without prejudice to the status of other content,
                        Data-Fusion will not be liable for the accuracy of
                        information or the claims made in the Sponsored ads.
                        <br />
                        Data-Fusion does not encourage the Users to visit the
                        Sponsored ads page or to avail any services from them.
                        Data-Fusion will not be liable for the services of the
                        providers of the Sponsored ads. You represent and
                        warrant that you will use these Services in accordance
                        with applicable law. Any contravention of applicable law
                        as a result of your use of these Services is your sole
                        responsibility, and Data-Fusion accepts no liability for
                        the same.
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>Data-Fusion MEDICINE INFORMATION</h5>
                      <p>
                        For detailed terms and conditions regarding medicine
                        information click{" "}
                        <a
                          href="https://www.practo.com/medicine-info/terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>{" "}
                        .
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>BOOK APPOINTMENT AND CALL FACILITY</h5>
                    </li>
                    <ul>
                      <li>
                        As a valuable partner on our platform we want to ensure
                        that the Practitioners experience on the Data-Fusion
                        booking platform is beneficial to both, Practitioners
                        and their Users. For all terms and conditions of Book
                        facility on Data-Fusion profile check{" "}
                        <a
                          href="https://www.practo.com/company/prime-benefits-providers"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Book Standards .
                        </a>{" "}
                      </li>
                      <li>
                        Practitioner understands that, Data-Fusion shall not be
                        liable, under any event, for any comments or feedback
                        given by any of the Users in relation to the Services
                        provided by Practitioner. The option of publishing or
                        modifying or moderating or masking (where required by
                        law or norm etc.) the feedback provided by Users shall
                        be solely at the discretion of Data-Fusion.
                      </li>
                    </ul>
                    <br></br>
                    <li>
                      <h5> PRACTITIONER UNDERTAKING</h5>
                      <p>
                        The Practitioner is and shall be duly registered,
                        licensed and qualified to practice medicine/ provide
                        health care, wellness services, as per applicable
                        laws/regulations/guidelines set out by competent
                        authorities and the Practitioner shall not be part of
                        any arrangement which will prohibit him/her from
                        practicing medicine within the territory of India. The
                        Practitioner shall at all times ensure that all the
                        applicable laws that govern the Practitioner shall be
                        followed and utmost care shall be taken in terms of the
                        consultation/ services being rendered.
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>USAGE IN PROMOTIONAL & MARKETING MATERIALS</h5>
                      <p>
                        In recognition of the various offerings and services
                        provided by Data-Fusion to Practitioner, Practitioner
                        shall (subject to its reasonable right to review and
                        approve): (a) allow Data-Fusion to include a brief
                        description of the services provided to Practitioner in
                        Data-Fusion's marketing, promotional and advertising
                        materials; (b) allow Data-Fusion to make reference to
                        Practitioner in case studies, and related marketing
                        materials; (c) serve as a reference to Data-Fusion's
                        existing and potential clients; (d) provide video logs,
                        testimonials, e-mailers, banners, interviews to the news
                        media and provide quotes for press releases; (e) make
                        presentations at conferences; and/or (f) use the
                        Practitioner's name and/or logo, brand images, tag lines
                        etc., within product literature, e-mailers, press
                        releases, social media and other advertising, marketing
                        and promotional materials.
                      </p>
                    </li>
                  </ul>
                  <li>
                    <h4>RIGHTS AND OBLIGATIONS RELATING TO CONTENT</h4>
                  </li>
                  <ul>
                    <li>
                      As mandated by Regulation 3(2) of the IG Rules, EMR
                      Connect hereby informs Users that they are not permitted
                      to host, display, upload, modify, publish, transmit,
                      update or share any information that:
                    </li>
                    <ul>
                      <li>
                        belongs to another person and to which the User does not
                        have any right to;
                      </li>
                      <li>
                        {" "}
                        is grossly harmful, harassing, blasphemous, defamatory,
                        obscene, pornographic, pedophilic, libelous, invasive of
                        another's privacy, hateful, or racially, ethnically
                        objectionable, disparaging, relating or encouraging
                        money laundering or gambling, or otherwise unlawful in
                        any manner whatever;
                      </li>
                      <li>harm minors in any way;</li>
                      <li>
                        {" "}
                        infringes any patent, trademark, copyright or other
                        proprietary rights;
                      </li>
                      <li>violates any law for the time being in force;</li>
                      <li>
                        deceives or misleads the addressee about the origin of
                        such messages or communicates any information which is
                        grossly offensive or menacing in nature;
                      </li>
                      <li>impersonate another person;</li>
                      <li>
                        contains software viruses or any other computer code,
                        files or programs designed to interrupt, destroy or
                        limit the functionality of any computer resource;
                      </li>
                      <li>
                        threatens the unity, integrity, defence, security or
                        sovereignty of India, friendly relations with foreign
                        states, or public order or causes incitement to the
                        commission of any cognizable offence or prevents
                        investigation of any offence or is insulting any other
                        nation.
                      </li>
                    </ul>
                    <li>Users are also prohibited from:</li>
                    <ul>
                      <li>
                        violating or attempting to violate the integrity or
                        security of the Website or any Data-Fusion Content;
                      </li>
                      <li>
                        transmitting any information (including job posts,
                        messages and hyperlinks) on or through the Website that
                        is disruptive or competitive to the provision of
                        Services by Data-Fusion;
                      </li>
                      <li>
                        intentionally submitting on the Website any incomplete,
                        false or inaccurate information;
                      </li>
                      <li>
                        making any unsolicited communications to other Users;
                      </li>
                      <li>
                        using any engine, software, tool, agent or other device
                        or mechanism (such as spiders, robots, avatars or
                        intelligent agents) to navigate or search the Website;
                      </li>
                      <li>
                        attempting to decipher, decompile, disassemble or
                        reverse engineer any part of the Website;
                      </li>
                      <li>
                        copying or duplicating in any manner any of the EMR
                        Connect Content or other information available from the
                        Website;
                      </li>
                      <li>
                        framing or hot linking or deep linking any EMRLink
                        Gateway Content.
                      </li>
                      <li>
                        {" "}
                        circumventing or disabling any digital rights
                        management, usage rules, or other security features of
                        the Software.
                      </li>
                    </ul>
                    <li>
                      Data-Fusion, upon obtaining knowledge by itself or been
                      brought to actual knowledge by an affected person in
                      writing or through email signed with electronic signature
                      about any such information as mentioned above, shall be
                      entitled to disable such information that is in
                      contravention of Clauses 5.1 and 5.2. Data-Fusion shall
                      also be entitled to preserve such information and
                      associated records for at least 90 (ninety) days for
                      production to governmental authorities for investigation
                      purposes.
                    </li>
                    <li>
                      In case of non-compliance with any applicable laws, rules
                      or regulations, or the Agreement (including the Privacy
                      Policy) by a User, Data-Fusion has the right to
                      immediately terminate the access or usage rights of the
                      User to the Website and Services and to remove
                      non-compliant information from the Website.
                    </li>
                    <li>
                      Data-Fusion may disclose or transfer User-generated
                      information to its affiliates or governmental authorities
                      in such manner as permitted or required by applicable law,
                      and you hereby consent to such transfer. The SPI Rules
                      only permit Data-Fusion to transfer sensitive personal
                      data or information including any information, to any
                      other body corporate or a person in India, or located in
                      any other country, that ensures the same level of data
                      protection that is adhered to by Data-Fusion as provided
                      for under the SPI Rules, only if such transfer is
                      necessary for the performance of the lawful contract
                      between Data-Fusion or any person on its behalf and the
                      User or where the User has consented to data transfer.
                    </li>
                    <li>
                      {" "}
                      Data-Fusion respects the intellectual property rights of
                      others and we do not hold any responsibility for any
                      violations of any intellectual property rights
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>Termination</h4>
                  </li>
                  <ul>
                    <li>
                      Data-Fusion reserves the right to suspend or terminate a
                      User's access to the Website and the Services with or
                      without notice and to exercise any other remedy available
                      under law, in cases where,
                    </li>
                    <ul>
                      <li>
                        Such User breaches any terms and conditions of the
                        Agreement;
                      </li>
                      <li>
                        A third party reports violation of any of its right as a
                        result of your use of the Services;
                      </li>
                      <li>
                        Data-Fusion is unable to verify or authenticate any
                        information provide to Data-Fusion by a User;
                      </li>
                      <li>
                        {" "}
                        Data-Fusion has reasonable grounds for suspecting any
                        illegal, fraudulent or abusive activity on part of such
                        User; or
                      </li>
                      <li>
                        Data-Fusion believes in its sole discretion that User's
                        actions may cause legal liability for such User, other
                        Users or for Data-Fusion or are contrary to the
                        interests of the Website.
                      </li>
                    </ul>
                    <li>
                      Once temporarily suspended, indefinitely suspended or
                      terminated, the User may not continue to use the Website
                      under the same account, a different account or re-register
                      under a new account. On termination of an account due to
                      the reasons mentioned herein, such User shall no longer
                      have access to data, messages, files and other material
                      kept on the Website by such User. The User shall ensure
                      that he/she/it has continuous backup of any medical
                      services the User has rendered in order to comply with the
                      User's record keeping process and practices.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>LIMITATION OF LIABILITY</h4>
                    <p>
                      In no event, including but not limited to negligence,
                      shall Data-Fusion, or any of its directors, officers,
                      employees, agents or content or service providers
                      (collectively, the "Protected Entities") be liable for any
                      direct, indirect, special, incidental, consequential,
                      exemplary or punitive damages arising from, or directly or
                      indirectly related to, the use of, or the inability to
                      use, the Website or the content, materials and functions
                      related thereto, the Services, User's provision of
                      information via the Website, lost business or lost
                      End-Users, even if such Protected Entity has been advised
                      of the possibility of such damages. In no event shall the
                      Protected Entities be liable for:
                    </p>
                  </li>
                  <ul>
                    <li>
                      provision of or failure to provide all or any service by
                      Practitioners to End- Users contacted or managed through
                      the Website;
                    </li>
                    <li>
                      any content posted, transmitted, exchanged or received by
                      or on behalf of any User or other person on or through the
                      Website;
                    </li>
                    <li>
                      any unauthorized access to or alteration of your
                      transmissions or data; or
                    </li>
                    <li>
                      any other matter relating to the Website or the Service.
                      <br />
                      In no event shall the total aggregate liability of the
                      Protected Entities to a User for all damages, losses, and
                      causes of action (whether in contract or tort, including,
                      but not limited to, negligence or otherwise) arising from
                      this Agreement or a User's use of the Website or the
                      Services exceed, in the aggregate Rs. 1000/- (Rupees One
                      Thousand Only).
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>RETENTION AND REMOVAL</h4>
                    <p>
                      Data-Fusion may retain such information collected from
                      Users from its Website or Services for as long as
                      necessary, depending on the type of information; purpose,
                      means and modes of usage of such information; and
                      according to the SPI Rules. Computer web server logs may
                      be preserved as long as administratively necessary.
                    </p>
                  </li>
                  <br></br>
                  <li>
                    <h4>APPLICABLE LAW AND DISPUTE SETTLEMENT</h4>
                  </li>
                  <ul>
                    <li>
                      You agree that this Agreement and any contractual
                      obligation between Data-Fusion and User will be governed
                      by the laws of India.
                    </li>
                    <li>
                      Any dispute, claim or controversy arising out of or
                      relating to this Agreement, including the determination of
                      the scope or applicability of this Agreement to arbitrate,
                      or your use of the Website or the Services or information
                      to which it gives access, shall be determined by
                      arbitration in India, before a sole arbitrator appointed
                      by Data-Fusion. Arbitration shall be conducted in
                      accordance with the Arbitration and Conciliation Act,
                      1996. The seat of such arbitration shall be Bangalore. All
                      proceedings of such arbitration, including, without
                      limitation, any awards, shall be in the English language.
                      The award shall be final and binding on the parties to the
                      dispute.
                    </li>
                    <li>
                      Subject to the above Clause 9.2, the courts at Bengaluru
                      shall have exclusive jurisdiction over any disputes
                      arising out of or in relation to this Agreement, your use
                      of the Website or the Services or the information to which
                      it gives access.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>CONTACT INFORMATION GRIEVANCE OFFICER</h4>
                  </li>
                  <ul>
                    <li>
                      If a User has any questions concerning Data-Fusion, the
                      Website, this Agreement, the Services, or anything related
                      to any of the foregoing, Data-Fusion customer support can
                      be reached at the following email address: support@EMR
                      Connect.com or via the contact information available from
                      the following hyperlink:
                      <a
                        href="http://www.Data-Fusion.com/contact"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.Data-Fusion.com/contact.
                      </a>
                    </li>
                    <li>
                      In accordance with the Information Technology Act, 2000,
                      and the rules made there under, if you have any grievance
                      with respect to the Website or the service, including any
                      discrepancies and grievances with respect to processing of
                      information, you can contact our Grievance Officer at:
                      <br />
                      Name: Surabhi Patodia <br /> Data-Fusion Technologies Pvt
                      Ltd <br />
                      WeWork Salarpuria Symbiosis, <br />
                      Arekere Village, Begur Hobli,
                      <br />
                      Bannerghatta Road,
                      <br /> Bangalore - 560076. <br /> Phone:{" "}
                      <a href="tel:+918880588999">8880588999</a> <br /> Email:{" "}
                      <a href="mailto:privacy@Data-Fusion.com">
                        privacy@Data-Fusion.com
                      </a>
                      <br />
                      In the event you suffer as a result of access or usage of
                      our Website by any person in violation of Rule 3 of the IG
                      Rules, please address your grievance to the above person.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>SEVERABILITY</h4>
                    <p>
                      If any provision of the Agreement is held by a court of
                      competent jurisdiction or arbitral tribunal to be
                      unenforceable under applicable law, then such provision
                      shall be excluded from this Agreement and the remainder of
                      the Agreement shall be interpreted as if such provision
                      were so excluded and shall be enforceable in accordance
                      with its terms; provided however that, in such event, the
                      Agreement shall be interpreted so as to give effect, to
                      the greatest extent consistent with and permitted by
                      applicable law, to the meaning and intention of the
                      excluded provision as determined by such court of
                      competent jurisdiction or arbitral tribunal.
                    </p>
                  </li>
                  <br></br>
                  <li>
                    <h4>WAIVER</h4>
                    <p>
                      No provision of this Agreement shall be deemed to be
                      waived and no breach excused, unless such waiver or
                      consent shall be in writing and signed by Data-Fusion. Any
                      consent by Data-Fusion to, or a waiver by EMRLink Gateway
                      of any breach by you, whether expressed or implied, shall
                      not constitute consent to, waiver of, or excuse for any
                      other different or subsequent breach.
                    </p>
                  </li>
                  <br></br>
                  <li>
                    <h4>BRITANNIA NUTRICHOICE OFFER TERMS AND CONDITIONS</h4>
                    <p>
                      These terms and conditions <b>("Terms")</b> are set out in
                      addition to the terms set out above this section on this
                      web page <b>("General Terms")</b> and apply to grant of
                      benefit in the form of credits in the Data-Fusion accounts
                      of the Users <b>("Data-Fusion HealthCash")</b> in the form
                      and under such circumstances as set out in these Terms by
                      usage of the coupon codes available along with the
                      Britannia Products (defined below){" "}
                      <b>("HealthCash Activation Codes")</b>. The offer of EMR
                      Connect HealthCash is available for the users of the
                      Website <b>("Users")</b> during the period October 1, 2018
                      till September 30, 2019 (both days included)
                      <b>("Offer Period")</b> only. Details of the Terms are set
                      out below:
                    </p>
                  </li>
                  <ul>
                    <li>
                      The offer of Data-Fusion HealthCash can be availed by
                      User(s) by purchase of Britannia Nutri Choice Essentials
                      150 g Oat Cookies and Britannia Nutri Choice Essentials
                      150 g Ragi Cookies <b>("Britannia Products")</b>, during
                      the Offer Period in accordance with the Terms herein. The
                      offer on Britannia Products specified in these Terms are
                      hereinafter referred to as <b>"Nutrichoice Offer"</b>.
                    </li>
                    <li>
                      The HealthCash Activation Codes are valid for activation
                      up to September 30, 2019 <b>("Activation Last Date")</b>,
                      post which the HealthCash Activation Codes cannot be used
                      for activating the Data-Fusion HealthCash.
                    </li>
                    <li>
                      In order to activate the Data-Fusion HealthCash through
                      the HealthCash Activation Code, the User will need to send
                      the following text message to the short-code number{" "}
                      <b>87223 87223</b>: [Data-Fusion &lt;HealthCash Activation
                      Code &gt;]
                      <b>("Activation Request")</b>.
                    </li>
                    <li>
                      By sending the Activation Request, the User agrees to the
                      Terms as well as the General Terms, and in case the User
                      is not already a registered user on Data-Fusion's
                      platform, i.e. www.Data-Fusion.com and/or EMRLink
                      Gateway's mobile application <b>("Website")</b>, he/she
                      agrees to create a Data-Fusion account on the Website
                      linked to the mobile number from which the User sends the
                      Activation Request. The complete terms and conditions
                      pertaining to this offer are provided here. Please go
                      through the entire terms on this web page in order to view
                      the complete terms for this Nutrichoice Offer.
                    </li>
                    <li>
                      Each HealthCash Activation Code can only be used once by
                      the Users.
                    </li>
                    <li>
                      Each HealthCash Activation Code will allow the User to
                      activate Data-Fusion HealthCash equivalent to INR 50
                      (Indian Rupees Fifty Only).
                    </li>
                    <li>
                      A User will be able to activate Data-Fusion HealthCash
                      through the Nutrichoice Offer only for a maximum of 3
                      (three) times in his/her Data-Fusion account by using the
                      HealthCash Activation Codes. Once a User uses his/her
                      first HealthCash Activation Code to activate EMRLink
                      Gateway HealthCash, such User cannot use the second
                      HealthCash Activation Code without redeeming the existing
                      Data-Fusion HealthCash in the User's account with
                      Data-Fusion. The same logic applies when the User is
                      activating Data-Fusion HealthCash by using the third
                      HealthCash Activation Code.
                    </li>
                    <li>
                      Data-Fusion HealthCash activated through the NutriChoice
                      Offer can be redeemed by the User only to pay online for
                      the following services on the Website:
                    </li>
                    <ul>
                      <li>ordering medicines online;</li>
                      <li> consulting a doctor online;</li>
                      <li>booking diagnostic tests and health check-ups;</li>
                      <li> pre-paying for doctor appointment bookings.</li>
                      <p>
                        (The above services on the Website are collectively
                        referred to as <b>"Permitted Services"</b>.)
                      </p>
                    </ul>
                    <li>
                      Data-Fusion HealthCash activated through one HealthCash
                      Activation Code will be available for the User for a
                      period of 14 (fourteen) days from the date of activation
                      of the Data-Fusion HealthCash by the User. After this
                      period, these allotted Data-Fusion HealthCash will expire,
                      if the User does not redeem such Data-Fusion HealthCash in
                      any of the Permitted Services.
                    </li>
                    <li>
                      Data-Fusion is not liable to accept activation of EMR
                      Connect HealthCash through any HealthCash Activation Code
                      which a User has received out of an unsealed pack of a
                      Britannia Product.
                    </li>
                    <li>
                      Data-Fusion is not liable to accept activation of EMR
                      Connect HealthCash through any HealthCash Activation Code
                      in case such HealthCash Activation Code has already been
                      used as per Data-Fusion's systems, even in case such
                      HealthCash Activation Code has been taken out from a
                      sealed pack of a Britannia Product. Data-Fusion shall have
                      the sole discretion in this matter.
                    </li>
                    <li>
                      The User acknowledges that Data-Fusion has agreed to offer
                      Data-Fusion HealthCash in its sole discretion. Nothing in
                      these Terms or the General Terms shall be interpreted to
                      obligate Data-Fusion to offer EMRLink Gateway HealthCash
                      beyond the Offer Period or to restrict its right to
                      withdraw the NutriChoice Offer during the Offer Period.
                      Data-Fusion reserves its discretion to deny Data-Fusion
                      HealthCash to any User in its sole discretion,
                      notwithstanding compliance by such User of the
                      requirements set out in these Terms, the General Terms,
                      applicable law or otherwise.
                    </li>
                    <li>
                      The User should raise all concerns pertaining to the
                      Britannia Products with Britannia Industries Limited
                      <b>("Britannia")</b> as per the contact details specified
                      on Britannia products packaging. Data-Fusion shall not be
                      liable in any manner in this regard.
                    </li>
                    <li>
                      The User should raise all concerns pertaining to the
                      Permitted Services with Data-Fusion Technologies Private
                      Limited <b>("Data-Fusion")</b> at{" "}
                      <a href="mailto:support@Data-Fusion.com">
                        support@Data-Fusion.com
                      </a>
                      Britannia shall not be liable in any manner in this
                      regard.
                    </li>
                    <li>
                      All capitalized terms used but not defined herein will
                      have the same meaning as ascribed to them under the
                      General Terms. To the extent of any inconsistency between
                      these Terms and the General Terms, the General Terms shall
                      prevail.
                    </li>
                    <li>
                      The User agrees that these Terms shall be governed in
                      accordance with the laws of India.
                    </li>
                    <li>
                      Any dispute arising out of these Terms shall be determined
                      by arbitration in India, before a sole arbitrator
                      appointed by Data-Fusion. Arbitration shall be conducted
                      in accordance with the Arbitration and Conciliation Act,
                      1996. The seat of such arbitration shall be Bangalore. All
                      proceedings of such arbitration, including, without
                      limitation, any awards, shall be in the English language.
                      The arbitration award shall be final and binding on the
                      parties to the dispute.
                    </li>
                    <li>
                      Subject to the terms of Clause 17 above, the courts of
                      Bangalore shall have exclusive jurisdiction to try all
                      disputes arising pursuant to or under these Terms.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    <h4>
                      14. Data-Fusion HEALTH PLAN – USER TERMS AND CONDITIONS
                    </h4>
                    <p>
                      For the purposes of the following terms and conditions
                      <b>("Data-Fusion Health Plan Terms")</b>, the service
                      provided to You (as defined below) is facilitation of
                      consultation and other benefits, as detailed hereinafter,
                      with the Practitioners (healthcare providers including all
                      its doctors, agents, employees) registered pharmacies
                      which facilitate the medicine orders{" "}
                      <b>("Registered Pharmacies")</b>
                      and diagnostic centres which conduct diagnostic tests
                      <b>("Diagnostic Centres")</b>, and other benefits the EMR
                      Connect may offer in future, as a part of the subscription
                      to the Data-Fusion Health Plan{" "}
                      <b>("Health Plan Service" / "Plan")</b>. Data-Fusion
                      operates and facilitates the Health Plan Service provided
                      through the Website.
                    </p>
                  </li>
                  <ul>
                    <li>
                      <h5>NATURE AND APPLICABILITY OF TERMS</h5>
                      <p>
                        Please go through these Data-Fusion Health Plan Terms
                        carefully before you decide to avail the Health Plan
                        Service facilitated by Data-Fusion on its Website. The
                        Data-Fusion Health Plan Terms set out herein are in
                        addition to the Data-Fusion Terms and Conditions
                        (available at{" "}
                        <a
                          href="https://www.Data-Fusion.com/terms_conditions"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.Data-Fusion.com/terms_conditions
                        </a>{" "}
                        ) read with the Privacy Policy (available at
                        <a
                          href="https://www.Data-Fusion.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.Data-Fusion.com/privacy
                        </a>{" "}
                        ://www.Data-Fusion.com/company/privacy), (the Terms and
                        Conditions and Privacy Policy are together referred as
                        <b>"Standard Policies"</b>) and together they constitute
                        a legal agreement between You and Data-Fusion. The
                        Standard Policies and the Data-Fusion Health Plan Terms
                        are to be read in connection with Your visit to the
                        Website and Your use of the Health Plan Service
                        respectively. To the extent of any inconsistency between
                        the Standard Policies and the Data-Fusion Health Plan
                        Terms herein, the Data-Fusion Health Plan Terms herein
                        shall prevail over the Standard Policies to the extent
                        of the inconsistency. <br /> These EMRLink Gateway
                        Health Plan Terms apply to any person who subscribes to
                        the Health Plan Service on behalf or for the benefit of
                        a patient, either individually or inclusive of his/her
                        family members / relatives. The primary user is required
                        to be registered on the Website and should have
                        subscribed to the Health Plan Service. For the purposes
                        of these Data-Fusion Health Plan Terms, the primary
                        subscriber to the Health Plan Service is an individual
                        designed as primary user <b>("Primary User")</b> in the
                        Plan Page (as defined hereafter), who subscribes to the
                        Health Plan Service either individually and/or for
                        benefit of other members, which could include any other
                        individual nominated by the Primary Member (hereinafter
                        collectively known as the <b>"Covered Members"</b>). The
                        Covered Members and a Primary User are collectively and
                        individually hereinafter referred to as the{" "}
                        <b>"You" / "Your" / "User" / "Users"</b>. As a part of
                        availing the Health Plan Service, You may choose
                        different packages available pursuant to the Health Plan
                        Service / Plan which best suits Your needs as per the
                        plan details set out in your Data-Fusion application{" "}
                        <b>("Plan Details")</b>. The User specific details will
                        be visible to You on Your purchased plan page{" "}
                        <b>("Plan Page")</b> hosted on Your Data-Fusion
                        application. <br /> If you have any questions about any
                        part of these Data-Fusion Health Plan Terms, feel free
                        to contact us at
                        <a href="mailto:support@Data-Fusion.com">
                          support@Data-Fusion.com
                        </a>{" "}
                        <br /> By availing the Health Plan Service, you signify
                        your acceptance of the terms of the Standard Policies
                        and these Data-Fusion Health Plan Terms. <br /> We
                        reserve the right to modify or terminate any portion of
                        these Data-Fusion Health Plan Terms and the Standard
                        Policies for any reason and at any time, and such
                        modifications shall be informed to You by appropriate
                        notifications on the Website. You should read these EMR
                        Connect Health Plan Terms and the Standard Policies at
                        regular intervals. Your continued availing of Health
                        Plan Service following any such modification constitutes
                        your agreement to follow and be bound by these Terms and
                        the Standard Policies so modified. <br /> You
                        acknowledge that you will be bound by these EMRLink
                        Gateway Health Plan Terms specifically for availing any
                        of the Health Plan Service. If you do not agree with any
                        part of these Data-Fusion Health Plan Terms and/or the
                        Standard Policies, please do not use the Website or
                        avail the Health Plan Service. <br /> Your access and
                        use of the Health Plan Service will be solely at the
                        discretion of Data-Fusion.
                      </p>
                    </li>
                    <li>
                      <h5>CONDITIONS OF USE</h5>
                      <p>
                        You must be eighteen (18) years of age or older to
                        register on the Website for availing the Health Plan
                        Service as a Primary User. As a Primary User, You can
                        add minors as Covered Member(s) under the Health Plan
                        Service availed and subscribed to with the express
                        understanding that the Primary Member shall be entirely
                        responsible and liable on all counts for compliance with
                        these Data-Fusion Health Plan Terms for and on behalf of
                        such Covered Member who is below the age of majority. By
                        registering, visiting and using the Website for availing
                        the Health Plan Service or accepting these Terms and the
                        Standard Policies, You represent and warrant to EMR
                        Connect that You are eighteen (18) years of age or older
                        and have the appropriate authorization to contract on
                        behalf of a Covered Member who is below the age of
                        majority, and that You have the right, authority and
                        capacity to use the Website for availing the Health Plan
                        Service and agree to and abide by these Terms.
                      </p>
                    </li>
                    <li>
                      <h5>TERMS OF THE HEALTH PLAN SERVICE</h5>
                    </li>
                    <ul>
                      <li>
                        Term: The Health Plan Service subscribed by You will be
                        valid for the period as per the Plan Details commencing
                        from the date of the subscription of the Health Plan
                        Service <b>("Term")</b>. After the expiry of the Term,
                        You may choose a different Plan as per the Plan Details
                        and subscribe thereto or renew Your existing
                        subscription, on such terms that are valid and
                        subsisting as on date of subscription or renewal. The
                        benefits under the Health Plan Service cannot be carried
                        forward after the expiry of the Term.
                      </li>
                      <li>
                        Registration: The Primary User is a registered user on
                        the Website and has subscribed to the Health Plan
                        Service on the Website by paying the applicable fees as
                        per the Plan Details. You could avail the Plan solely
                        for You or for Yourself and any Covered Member(s). Each
                        Covered Member, who is covered by the Health Plan
                        Service subscribed by You will have to follow an
                        authentication mechanism to utilize the Health Plan
                        Service. The maximum number of permissible Covered
                        Members per each Primary User will be set out in the
                        Plan Details. Covered Members, once added by the Primary
                        User cannot be removed, modified or edited.
                      </li>
                      <li>
                        Information: As a part of the registration process for
                        Health Plan Service, You will fill in certain personal
                        information and details in order to create a medical
                        history and profile which will be accessible to the
                        Practitioners. The information shared by You will be
                        dealt with in accordance with the Standard Policies.
                      </li>
                      <li>
                        Health Plan Service details: As a part of Health Plan
                        Service, You are entitled to benefits (which may include
                        such number of consultations, offers on medicines which
                        are ordered through the Website, discounted health
                        check-ups, discounts / offers on diagnostics tests and
                        Family Doctor benefit, or any other benefit that EMR
                        Connect may offer in the future) that are set out in the
                        Plan Details.
                      </li>
                      <li>
                        Health Plan Service process: After subscribing to Health
                        Plan Service, You will be eligible to avail the benefits
                        covered under the Health Plan Service as under:
                      </li>
                      <ul>
                        <li>
                          Consultations:{" "}
                          <b>Covered Consultations and their Utilization:</b> As
                          part of the Health Plan Service, You are entitled to
                          certain number of consultations with Covered
                          Practitioners at a discounted rate{" "}
                          <b>("Covered Consultations")</b>. For the purposes of
                          these Terms, "Covered Practitioners" are those
                          Practitioners who have been onboarded by EMRLink
                          Gateway to provide You the Covered Consultation as
                          part of Health Plan Service. The number of Covered
                          Consultations that You are eligible for shall be
                          displayed under Your Plan Page. The Covered
                          Consultations can be availed by You either by booking
                          through the Website by using the Book facility (as
                          published on the Website) or by way of a walk-in. It
                          is clarified that the benefits under the Covered
                          Consultations will be calculated cumulatively and not
                          per member. Illustratively, 1 Primary User has
                          subscribed to the Health Plan Service with 4 Covered
                          Members who are cumulatively entitled to 10 Covered
                          Consultations. This means that as a group (Primary
                          User and the Covered Members) the number of Covered
                          Consultations is 10. These 10 Covered Consultations
                          can be utilized by 1 individual User or more than 1
                          individual user in such combination and proportion
                          that they choose as a group. Further, if You have
                          exhausted the Covered Consultations under the Health
                          Plan Service, You will still be able to avail other
                          benefits which are a part of the Health Plan Service,
                          however, if You seek a consultation with the Covered
                          Practitioners after exhausting the Covered
                          Consultation, You will have to pay to the Covered
                          Practitioner their standard rate at their
                          establishment. If before the expiry of the Term, You
                          have exhausted the Covered Consultations that You were
                          eligible for, You may have an option to renew Health
                          Plan Service solely for the purpose of subscribing to
                          additional number of Covered Consultation during the
                          Term.
                          <br />
                          Authentication: If You are booking a Covered
                          Consultation through Data-Fusion's Book facility, then
                          the Plan Details, shall indicate such Covered
                          Consultation to have been consumed at the time of
                          booking such Covered Consultation, unless you have
                          cancelled it as per permissible timelines set out in
                          the Standard Policies. However, if You are availing
                          the Covered Consultation by way of a walk-in
                          appointment, then the Plan Details shall indicate such
                          Covered Consultation to have been consumed once You
                          authenticate Your booking at such Covered
                          Practitioner's establishment. Covered consultations do
                          not include consultations with Physiotherapist,
                          Psychiatrist, Ayurveda, and Homeopathy.
                        </li>
                        <li>
                          {" "}
                          Medicine: As part of the Health Plan Service, You are
                          eligible to receive offers on order of medicines (as
                          offered for sale by Registered Pharmacies which are
                          the sellers of such medicines) through the Website
                          during the Term as per the Plan Details. The type of
                          offers will be set out under your Plan Details. The
                          other terms and conditions governing the order of
                          medicines which are available at{" "}
                          <a
                            href="www.Data-Fusion.in"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.Data-Fusion.in
                          </a>
                          <b>("Order Terms")</b> will applicable to You in
                          conjunction with the Standard Policies. The offers on
                          purchase of medicines is available only on purchase of
                          medicines through the Website and not via any other
                          mode. For the purposes of these Data-Fusion Health
                          Plan Terms, "Registered Pharmacies" are those
                          pharmacies who have been on-boarded by Data-Fusion to
                          offer You sale of medicine as part of the Health Plan
                          Service through the Website. Additionally, the Order
                          Terms applicable to Data-Fusion and User on medicine
                          order will apply 'as is' under these EMRLink Gateway
                          Health Plan Terms. It is to be noted that Data-Fusion
                          is not the seller of the medicines but only a
                          facilitator which facilitates procurement of medicine
                          between You and a Registered Pharmacist. Data-Fusion
                          is in no manner liable for or responsible towards the
                          underlying medicine sale between the User and the
                          registered pharmacist. Data-Fusion hereby disclaims
                          and excludes all warranties with respect to all
                          services, information and/or products contained on a
                          medicine order, express, implied or statutory.
                        </li>
                        <li>
                          Diagnostics: As part of Health Plan Service, You will
                          be eligible to receive discounts / offers on the
                          diagnostic tests availed by You (undertaken by
                          Diagnostic Centres) through the Website for the Term
                          as per the Plan Details. The quantum of the discount
                          or type of offers will be set out under your Plan
                          Details. The diagnostic healthcare services are
                          offered by Qikwell Technologies India Pvt. Ltd.
                          ("QTIPL") and the terms and conditions governing the
                          lab/diagnostic tests which are available at www.EMR
                          Connect.in will be applicable to You in conjunction
                          with the Standard Policies. It is to be noted that the
                          terms applicable to the User on diagnostic tests will
                          apply 'as is' under these Terms. It is clarified that
                          all diagnostic tests may not be covered under Health
                          Plan Service which is at the discretion of QTIPL.
                        </li>
                        <li>
                          Health Check-ups: As a benefit extended to You as a
                          subscriber to Health Plan Service, You will get health
                          check-ups at discounted fees during the Term. The
                          details (including the number) of health check-ups
                          will be visible to You under your Plan Page. As with
                          Covered Consultations, the Primary User and the
                          Covered Members may, as a group, choose to avail the
                          health check-ups in such proportion amongst themselves
                          as they deem fit. Health check-up would be provided by
                          the Covered Practitioners and the Diagnostic Centres.
                        </li>
                        <li>
                          Family Doctor: As a part of the Health Plan Service,
                          You will also be eligible to avail the benefit of
                          online tele-consultation with a team of Practitioners
                          <b>("Family Doctor")</b>. The Family Doctor will be
                          available to You for consult and You are not required
                          to pay any additional sum to consult with such Family
                          Doctor. The Family Doctor benefit may be availed
                          either through a phone call or chat or a video call.
                          The Standard Policies applicable to You with regard to
                          the online consultation (Data-Fusion Consult /
                          Consult) will apply "as is". Please note that the
                          benefit of Family Doctor Consult is merely a
                          consulting model and any interactions and associated
                          issues with the Practitioner acting as Family Doctor,
                          including but not limited to the Your health issues
                          and/or Your experiences is strictly between You and
                          such Practitioner. You shall not hold Data-Fusion
                          responsible for any such interactions and associated
                          issues. Any conversations that You have had with
                          Family Doctor will be retained in Data-Fusion database
                          as per the applicable laws and subject to
                          confidentiality as laid out in the Standard Policies.
                          Additionally, Data-Fusion in no manner endorses any
                          Practitioner who is acting in the capacity of Family
                          Doctor, that You consult and is not in any manner
                          responsible for any drug/medicines prescribed or the
                          therapy prescribed by such Family Doctor on the basis
                          of the consultation. Further, Data-Fusion shall not be
                          responsible for any breach of service or service
                          deficiency by such Practitioner. It is further
                          clarified that Data-Fusion is not a medical service
                          provider, nor is it involved in providing any
                          healthcare or medical advice or diagnosis, it shall
                          hence not be responsible and owns no liability to
                          either You or any Practitioner for any outcome from
                          the consultation between You and such Practitioner.
                          The Family Doctor benefit is made available to You to
                          obtain consultation from a Practitioner as a part of
                          the Health Plan Service and is in no way intending to
                          replace physical consultations with the Practitioner.
                        </li>
                      </ul>
                      <br></br>
                      <li>
                        <h5>Payment for the Health Plan Service:</h5>
                        <p>
                          You will pay to Data-Fusion at the time of subscribing
                          to the Health Plan Service, at the rates set out in
                          the Plan Details. At the time of visiting the
                          establishment of the Practitioner for Covered
                          Consultation, You are not required to pay any amount
                          as long as your eligibility for Covered Consultations
                          are valid and has been set out in the Plan Page. After
                          availing the Covered Consultation service, You are
                          required to let the billing department of such
                          Practitioner's establishment know, that You have
                          availed it by way of a Covered Consultation as part of
                          Health Plan Service which will then be authenticated
                          by the Practitioner's establishment.
                        </p>
                      </li>
                      <br></br>
                      <li>
                        <h5>
                          Health Plan Service Cancellation and Refund Policy:
                        </h5>
                        <p>
                          You may have an option to cancel Your subscription to
                          the Health Plan Services. You will contact EMRLink
                          Gateway on the customer support chat channel regarding
                          cancelling Your subscription to the Health Plan
                          Service. Further, Data-Fusion may refund the amount
                          paid by You towards your subscription of the Health
                          Plan Services ("Health Plan Subscription amount"),
                          subject to the following conditions:
                        </p>
                      </li>
                      <ul>
                        <li>
                          In the event the Health Plan Subscription amount is
                          Rs. 3,999 (Rupees Three Thousand Nine Hundred and
                          Ninety Nine) or Rs. 5,999 (Rupees Five Thousand Nine
                          Hundred and Ninety Nine) the refund scheme is as
                          follows:
                        </li>
                        <ul>
                          <li>
                            If the user has not availed any of the Health Plan
                            Services which are mentioned in the User's Plan
                            Details within a period of 15 (Fifteen) days from
                            the date of subscribing to the Health Plan Services
                            ("Free Look Period") and if the user requests for a
                            cancellation of his/her Health Plan Services during
                            the Free Look Period, Data-Fusion will refund the
                            entire Health Plan Subscription amount which was
                            paid by the user.
                          </li>
                          <li>
                            If the user requests for cancellation of his/her
                            subscription to the Health Plan Services during the
                            Free Look Period and if the user has already availed
                            certain Health Plan Services which are mentioned in
                            the user's Plan Details during the Free Look Period,
                            Data-Fusion will deduct maximum of Rs. 1,000 (Rupees
                            One Thousand) or the actual value of the Health Plan
                            Services availed by the user, whichever is higher.
                          </li>
                          <li>
                            If the user requests for cancellation of his/her
                            subscription to the Health Plan Services after
                            expiry of the Free Look Period, Data-Fusion will
                            refund the Health Plan Subscription amount in the
                            following manner:
                          </li>
                          <ul>
                            <li>
                              If the cancellation request by the user is within
                              16 (Sixteen) days to 89 (Eighty Nine) days from
                              the date of user's subscription to the Health Plan
                              Services, Data-Fusion will refund 75% (Seventy
                              Five percent) of the Health Plan Subscription
                              amount or the actual cost of Health Plan Services
                              availed by the user, whichever is higher;
                            </li>
                            <li>
                              If the cancellation request by the user is within
                              90 (Ninety) days to 179 (One hundred and Seventy
                              Nine) days from the date of user's subscription to
                              the Health Plan Services, Data-Fusion will refund
                              50% (Fifty percent) of the Health Plan
                              Subscription amount or the actual cost of Health
                              Plan Services availed by the user, whichever is
                              higher;
                            </li>
                            <li>
                              If the cancellation request by the user is within
                              180 (One Hundred and Eighty) days to 269 (Two
                              hundred and Sixty Nine) from the date of user's
                              subscription to the Health Plan Services, EMR
                              Connect will refund 25% (Twenty Five percent) of
                              the Health Plan Subscription amount or the actual
                              cost of Health Plan Services availed by the user,
                              whichever is higher; and
                            </li>
                            <li>
                              The user will not be entitled to any refund of the
                              Health Plan Subscription amount if the
                              cancellation request by the user is after 269 (Two
                              Hundred and Sixty Nine) from the date of user's
                              subscription to the Health Plan Services.
                            </li>
                          </ul>
                        </ul>
                        <li>
                          In the event the Health Plan Subscription amount paid
                          by the user is Rs. 1,999 (Rupees One Thousand Nine
                          Hundred and Ninety Nine) or Rs.2,999 (Rupees Two
                          Thousand Nine Hundred and Ninety Nine) the refund
                          scheme is as follows:
                        </li>
                        <ul>
                          <li>
                            If the user has not availed any of the Health Plan
                            Services which are mentioned in the user's Plan
                            Details within the Free Look Period and if the user
                            requests for a cancellation of his/her Health Plan
                            Services during the Free Look Period, EMRLink
                            Gateway will refund the entire Health Plan
                            Subscription amount which was paid by the user.
                          </li>
                          <li>
                            If the user requests for cancellation of his/her
                            subscription to the Health Plan Services during the
                            Free Look Period and if the user has already availed
                            certain Health Plan Services which are mentioned in
                            the user's Plan Details during the Free Look Period,
                            Data-Fusion will refund the Health Plan Subscription
                            amount after deducting the consultation fees (as
                            mentioned by the respective doctor on the Website)
                            for the consultation(s) availed by the user as part
                            of the Health Plan Services.
                          </li>
                          <li>
                            If the user requests for cancellation of his/her
                            subscription to the Health Plan Services after
                            expiry of the Free Look Period, Data-Fusion will
                            refund the Health Plan Subscription amount in the
                            following manner:
                          </li>
                          <ul>
                            <li>
                              If the cancellation request by the user is within
                              16 (Sixteen) days to 89 (Eighty Nine) days from
                              the date of user's subscription to the Health Plan
                              Services, Data-Fusion will refund 75% (Seventy
                              Five percent) of the Health Plan Subscription
                              amount or the actual cost of Health Plan Services
                              availed by the user, whichever is higher;
                            </li>
                            <li>
                              If the cancellation request by the user is within
                              90 (Ninety) days to 179 (One hundred and Seventy
                              Nine) days from the date of user's subscription to
                              the Health Plan Services, Data-Fusion will refund
                              50% (Fifty percent) of the Health Plan
                              Subscription amount or the actual cost of Health
                              Plan Services availed by the user, whichever is
                              higher;
                            </li>
                            <li>
                              If the cancellation request by the user is within
                              180 (One Hundred and Eighty) days to 269 (Two
                              hundred and Sixty Nine) days from the date of
                              user's subscription to the Health Plan Services,
                              Data-Fusion will refund 25% (Twenty Five percent)
                              of the Health Plan Subscription amount or the
                              actual cost of Health Plan Services availed by the
                              user, whichever is higher; and
                            </li>
                            <li>
                              The user will not be entitled to any refund of the
                              Health Plan Subscription amount if the
                              cancellation request by the user is after 269 (Two
                              Hundred and Sixty Nine) days from the date of
                              user's subscription to the Health Plan Services.
                            </li>
                          </ul>
                        </ul>
                        <li>
                          In the event the Health Plan Subscription amount paid
                          by the user is Rs.399 (Rupees Three Hundred and Ninety
                          Nine) or Rs.499 (Rupees Four Hundred and Ninety Nine)
                          or Rs.799 (Rupees Seven Hundred and Ninety Nine)the
                          refund scheme is as follows:
                        </li>
                        <ul>
                          <li>
                            If the user has not availed any of the Health Plan
                            Services which are mentioned in the user's Plan
                            Details within a period of 7 (Seven) days from the
                            date of subscribing to the Health Plan Services
                            ("Free Look Period") and if the user requests for a
                            cancellation of his/her Health Plan Services during
                            the Free Look Period, Data-Fusion will refund the
                            entire Health Plan Subscription amount which was
                            paid by the user.
                          </li>
                          <li>
                            If the user requests for cancellation of his/her
                            subscription to the Health Plan Services during the
                            Free Look Period and if the user has already availed
                            certain Health Plan Services which are mentioned in
                            the user's Plan Details during the Free Look Period,
                            Data-Fusion will refund the Health Plan Subscription
                            amount after deducting the consultation fees (as
                            mentioned by the respective doctor on the Website)
                            for the consultation(s) availed by the user as part
                            of the Health Plan Services.
                          </li>
                          <li>
                            The user will not be entitled to any refund of the
                            Health Plan Subscription amount if the cancellation
                            request by the user is after 7 (Seven) days from the
                            date of user's subscription to the Health Plan
                            Services.
                          </li>
                        </ul>
                        <li>
                          In the event the Health Plan Subscription amount paid
                          by the user is Rs.1499 (Rupees One Thousand Four
                          Hundred and Ninety Nine) the refund scheme is as
                          follows:
                        </li>
                        <ul>
                          <li>
                            {" "}
                            If the user has not availed any of the Health Plan
                            Services which are mentioned in the user's Plan
                            Details within a period of 15 (Fifteen) days from
                            the date of subscribing to the Health Plan Services
                            ("Free Look Period") and if the user requests for a
                            cancellation of his/her Health Plan Services during
                            the Free Look Period, Data-Fusion will refund the
                            entire Health Plan Subscription amount which was
                            paid by the user.
                          </li>
                          <li>
                            {" "}
                            If the user requests for cancellation of his/her
                            subscription to the Health Plan Services during the
                            Free Look Period and if the user has already availed
                            certain Health Plan Services which are mentioned in
                            the user's Plan Details during the Free Look Period,
                            Data-Fusion will refund the Health Plan Subscription
                            amount after deducting the consultation fees (as
                            mentioned by the respective doctor on the Website)
                            for the consultation(s) availed by the user as part
                            of the Health Plan Services.
                          </li>
                          <li>
                            The user will not be entitled to any refund of the
                            Health Plan Subscription amount if the cancellation
                            request by the user is after 15 (Fifteen) days from
                            the date of user's subscription to the Health Plan
                            Services.
                          </li>
                        </ul>
                        <li>
                          All refunds of the Health Plan Subscription amount to
                          the user are subject to Data-Fusion's internal
                          verification process.
                        </li>
                        <li>
                          Subject to Clause 3.4.7 (3) above, the refund of the
                          Health Plan Subscription amount will made to the user
                          within fifteen (15) business days of such cancellation
                          request.
                        </li>
                      </ul>
                      <li>
                        As a part of Health Plan Service, You are entitled to
                        certain discounts, offers and benefits. It is clarified
                        that none of these benefits under Health Plan Service
                        can be redeemed for cash.
                      </li>
                      <br></br>
                      <li>
                        <h5>Fair Usage Policy for Data-Fusion Health Plan.</h5>
                        <p>
                          The following fair usage policy shall be applicable to
                          every Plan:
                        </p>
                      </li>
                      <ul>
                        <li>
                          Users are allowed to utilise a maximum of 5 (Five)
                          free online consultations per day (i.e. 5 (Five)
                          online consultations per day collectively for all
                          users, including covered members). Upon completion of
                          the 5 (Five) free consultations per day, users may
                          choose to avail additional consultations by paying the
                          prescribed consultation fee to the doctor.
                        </li>
                        <li>
                          Users are allowed to utilize a maximum of 15 (Fifteen)
                          free online consultations per month (i.e. 15 (Fifteen)
                          free online consultations per month collectively for
                          all users, including covered members). Upon completion
                          of the 15 (Fifteen) free consultations per month,
                          users may choose to avail additional consultations by
                          paying the prescribed consultation fee to the doctor.
                        </li>
                        <li>
                          Each user of a Plan can have 1 (one) active doctor
                          consultation at any given time. An online consultation
                          is considered to be active for 60 (Sixty) minutes from
                          the actual time of commencement of the said
                          consultation and upon completion of 60 (Sixty)
                          minutes, users may commence a new online consultation.
                        </li>
                        <li>
                          The aforementioned fair usage policy is subject to
                          change by Data-Fusion at its sole discretion based on
                          future analysis and actual usage.
                        </li>
                      </ul>
                    </ul>
                    <br></br>
                    <li>
                      <h5>APPLICABILITY OF THE STANDARD POLICIES</h5>
                      <p>
                        The Data-Fusion Health Plan Terms herein are required to
                        be read in conjunction and addition with the applicable
                        user terms under the Standard Policies. In case of any
                        inconsistency between the Data-Fusion Health Plan Terms
                        and the Standard Policies, the Terms will prevail to the
                        extent of the inconsistency.
                      </p>
                    </li>
                    <br></br>
                    <li>
                      <h5>
                        REIMBURSEMENT UNDER Data-Fusion PLUS PLAN – TERMS &
                        CONDITIONS
                      </h5>
                      <p>
                        Users who have subscribed to the Data-Fusion Plus Plan
                        which is available on the Website are entitled to
                        receive reimbursements, on the consultation fee
                        mentioned by the doctor on the Website with respect to
                        each consultation ("Consultation Fee") subject to the
                        following terms and conditions:
                      </p>
                    </li>
                    <ul>
                      <li>
                        Only those Users who have subscribed to a EMRLink
                        Gateway Plus Plan through the Website are entitled to
                        receive reimbursement on the Consultation Fee.
                      </li>
                      <li>
                        The maximum reimbursement which can be availed by a User
                        per consultation is Rs. 500/- (Rupees Five Hundred
                        only).
                      </li>
                      <li>
                        Reimbursement is available only on the Consultation
                        Fees, and not on the fees paid by the User for each
                        Consultation. For example, if the doctor has raised an
                        invoice for Rs. 500/- and the Consultation Fee as
                        mentioned under the profile of the doctor on the Website
                        is Rs. 400/-, the User who has consulted the said doctor
                        will be entitled to a cash back of only Rs. 400/- and
                        not Rs. 500/-.
                      </li>
                      <li>
                        For the purpose of availing the reimbursement, Users
                        shall send a legible picture/photo of the invoice/bill
                        raised by the respective clinic/hospital at which the
                        said User has booked an appointment to pluscashback@EMR
                        Connect.com.
                      </li>
                      <li>
                        The E-mail to be sent by the User (as per Clause 5
                        above) shall also include the following details:
                      </li>
                      <ul>
                        <li>Picture/photo of the invoice/bill</li>
                        <li>Bank details of the User</li>
                      </ul>
                      <li>
                        The picture/photo mentioned under Clause 5 shall contain
                        the following details for the Users to avail the
                        reimbursement:
                      </li>
                      <ul>
                        <li>
                          Name of the User (name should be as mentioned in the
                          Data-Fusion Plus membership before the booking the
                          appointment)
                        </li>
                        <li>Name of clinic/hospital</li>
                        <li>Name of doctor</li>
                        <li>Consultation Fee/ fees paid.</li>
                      </ul>
                      <li>
                        Data-Fusion team will verify the amount mentioned in the
                        invoice with the Consultation Fee mentioned by the
                        doctor on the Website
                      </li>
                      <li>
                        Any reimbursement claim made by a User after the expiry
                        of his/her subscription to the Data-Fusion Plus Plan is
                        not valid.
                      </li>
                      <li>
                        A User cannot carry forward his/her entitlement to
                        receive reimbursement while renewing his/her EMRLink
                        Gateway Plus Plan subscription.
                      </li>
                      <li>Plus is not an insurance product.</li>
                      <li>
                        Any reimbursement claim made by a User 30 days after the
                        date of appointment is not valid.
                      </li>
                    </ul>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserFooter />
    </>
  );
};

export default TermAndCondition;
