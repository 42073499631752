import React from "react";
import MasterUserLayout from "../../components/MasterUser/MasterUserLayout";
import {
  useGetCloudStorageDetailsQuery,
  useGetDashboardStatsQuery,
} from "../../services/apiService";
import CloudStorageModal from "../../components/elements/shared/CloudStorageModal";
import Spinner from "../../components/elements/Spinner/Spinner";

const MasterUserDashboard = () => {
  // const {
  //   data: cloudStorageDetails,
  //   error,
  //   isLoading,
  //   isFetching,
  //   refetch,
  // } = useGetCloudStorageDetailsQuery();

  const {
    data: dashboardDetails,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetDashboardStatsQuery();

  return (
    <>
      <MasterUserLayout>
        <div className="overview-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4>Welcome to Your Data Fusion Hub</h4>
                <p>Manage, analyse, and leverage your EMR data.</p>
                <form className="">
                  <label>Organizations</label>

                  <div className="row">
                    <div className="col-xl-4 col-sm-6">
                      <div className="dash-shadow-box flex-set first">
                        <div className="icon-box">
                          <i className="fa fa-users" aria-hidden="true"></i>
                        </div>
                        <div>
                          <h4>Users</h4>
                          <h1>{dashboardDetails?.total_users || 0}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div className="dash-shadow-box flex-set second">
                        <div className="icon-box">
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <div>
                          <h4>Customers</h4>
                          <h1>{dashboardDetails?.total_customers || 0}</h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div className="dash-shadow-box flex-set third">
                        <div className="icon-box">
                          <i className="fa fa-file" aria-hidden="true"></i>
                        </div>
                        <div>
                          <h4>Stored Files</h4>
                          <h1>{dashboardDetails?.total_extracted_data || 0}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Spinner isLoading={isLoading || isFetching} />
      </MasterUserLayout>
      {/* {cloudStorageDetails?.cloud_data === "" && <CloudStorageModal />} */}
    </>
  );
};

export default MasterUserDashboard;
