import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  loggedInUser: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.loggedInUser = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;
    },
    verifyOtp: (state, action) => {
      state.isOtpVerified = true;
    },
    logout: (state) => {
      state.loggedInUser = {};
      state.token = null;
      state.isLoggedIn = false;
      state.isOtpVerified = false;
      state.currentUser = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    },
  },
});

export const { logout, login, verifyOtp } = authSlice.actions;

export default authSlice.reducer;
