import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  page_size: 25,
  search: "",
  userList: [],
  total_pages: 0,
  totalRecords: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload.users;
      state.totalRecords = action.payload.total_record;
      state.total_pages = Math.ceil(
        action.payload.total_record / state.page_size
      );
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.page_size = action.payload;
      state.page = 1; // Reset to the first page
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { setUserList, setPage, setPageSize, setSearch } =
  userSlice.actions;

export default userSlice.reducer;
