import CryptoJS from "crypto-js";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../config/config";
import { decodeToken } from "react-jwt";
import { REVERSE_ROLES } from "../utils/commonFunction";

const useAuth = () => {
  const encryptedToken = localStorage.getItem("accessToken");
  const isOtpVerified = localStorage.getItem("isUserVerified") || false;

  if (encryptedToken === null) {
    return {
      userName: "",
      role: "",
      isAdmin: false,
      isUser: false,
      status: "",
    };
  }

  const token =
    encryptedToken !== null &&
    CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
      REACT_APP_CRYPTOJS_VECTOR,
    }).toString(CryptoJS.enc.Utf8);

  let isMasterUser = false;
  let isUser = false;
  let isAdmin = false;
  let status = "3";

  if (token) {
    const data = decodeToken(token);

    if (data === null) {
      return {
        userName: "",
        role: "",
        isAdmin,
        isUser,
        status,
      };
    } else {
      const role = REVERSE_ROLES[data?.user?.role];

      isMasterUser = role === "MASTERUSER" ? true : false;
      isUser = role === "USER" ? true : false;
      isAdmin = role === "ADMIN" ? true : false;

      if (isMasterUser) status = "MASTERUSER";
      if (isUser) status = "USER";
      if (isAdmin) status = "ADMIN";

      return {
        role,
        isAdmin,
        isUser,
        status,
        isOtpVerified,
      };
    }
  } else {
    return {
      role: "",
      isAdmin,
      isUser,
      status,
      isOtpVerified,
    };
  }
};

export default useAuth;
