import React, { useEffect } from "react";
import MasterUserLayout from "../../components/MasterUser/MasterUserLayout";
import BasicDetails from "../../components/MasterUser/ProfileTabComponents/BasicDetails";
import CloudDetails from "../../components/MasterUser/ProfileTabComponents/CloudDetails";
import { useLocation } from "react-router-dom";

const MasterUserProfile = () => {
  const location = useLocation();
  useEffect(() => {
    // Check if the state has the selectTab property set to "cloud"
    if (location.state?.selectTab === "cloud") {
      const cloudTabButton = document.querySelector(
        'button[data-bs-target="#cloud"]'
      );
      if (cloudTabButton) {
        // Trigger a click on the Cloud tab button
        cloudTabButton.click();
      }
    }
  }, [location.state]);
  useEffect(() => {
    const handleTabChange = (event) => {
      const target = event.target;
      if (target && target.getAttribute("data-bs-target") === "#cloud") {
        // Trigger an event to call API when Cloud tab is clicked
        const customEvent = new CustomEvent("cloudTabSelected");
        window.dispatchEvent(customEvent);
      }
    };

    const tabElements = document.querySelectorAll(
      'button[data-bs-toggle="tab"]'
    );
    tabElements.forEach((tabElement) => {
      tabElement.addEventListener("click", handleTabChange);
    });

    return () => {
      tabElements.forEach((tabElement) => {
        tabElement.removeEventListener("click", handleTabChange);
      });
    };
  }, []);
  return (
    <>
      <MasterUserLayout>
        <div className="overview-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-tabs gap-2" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Basic Details
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="cloud-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#cloud"
                      type="button"
                      role="tab"
                      aria-controls="cloud"
                      aria-selected="false"
                    >
                      Cloud Details
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <BasicDetails />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="cloud"
                    role="tabpanel"
                    aria-labelledby="cloud-tab"
                  >
                    <CloudDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterUserLayout>
    </>
  );
};

export default MasterUserProfile;
