import React from 'react'

const Error = () => {
  return (
    <>
     <div className='error-screen'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                    <div>
                        <h4>Page Not Found</h4>
                        <img src="/assets/images/error-img.jpg" className='img-fluid' alt="" />
                        <p>Looks like the page you're looking for doesn't exist.<br></br>
                        You can contact us through one of these channels:
                        <br></br>
                        </p>
                        <p><a href=''>Linkedin </a> | <a href=''> Facebook</a></p>
                        <a className='button-home'>Take me back Home</a>
                    </div>
                </div>
            </div>
        </div>
        </div> 
    </>
  )
}

export default Error
